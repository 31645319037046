/**
 * ```
 * @prettier
 * ```
 */

/**
 * Components
 */
import CheckoutInformation from "@/components/checkout-information/CheckoutInformationComponent.vue";
import Cart from "@/components/cart/CartComponent.vue";
import CheckoutComplete from "@/components/checkout-complete/CheckoutCompleteComponent.vue";

export default {
  name: "CheckoutView",

  components: {
    CheckoutInformation,
    Cart,
    CheckoutComplete,
  },

  /**
   * @inheritdoc
   */
  data() {
    return {
      isOrderSuccessfull: false,
    };
  },

  created() {
    this.isOrderSuccessfull = this.$store.getters["global/getIsCatalogueMode"];
  },

  methods: {
    /**
     * It sets order status to finish creation
     *
     * @param {boolean} success Order status
     * @returns {void}
     */
    setOrderSuccessfull(success) {
      this.isOrderSuccessfull = success;
      window.scrollTo(0, 0);

      // Google Analytics Event
      this.$gtag.event("Intent", {
        event_category: "Packbuilder - Intent",
        event_label: "Checkout",
      });
    },
  },
};
