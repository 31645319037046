// Components
import QuantityDropdown from '@/components/quantity-dropdown/QuantityDropdownComponent.vue';

export default {
  name: 'ProductModalQuantityComponent',
  components: {
    QuantityDropdown,
  },
  props: {
    quantity : {
      type: Number,
      required: true,
    },
    min : {
      type: Number,
      default: 1,
    },
    bulkMode: {
      type: Boolean,
      default: false,
    }
  },
  model: {
    prop: 'quantity',
    event: 'onQuantityChanged'
  },
  computed: {
    /**
     * Whether to disable decrease capability
     * 
     * @returns {boolean} Returns true if quantity is 1
     */
    decreaseDisabled() {
      return this.quantity <= this.min;
    }
  },
  methods: {
    /**
     * Emits onQuantityChanged event
     * 
     * @param {number} quantity -> quantity of the selected product
     * @returns {void}
     */
    updateQuantity(quantity) {
      this.$emit('onQuantityChanged', quantity);
    },

    /**
     * Increase quantity by 1
     *
     * @returns {void}
     */
    increaseQuantity() {
      this.updateQuantity(this.quantity + 1)
    },

    /**
     * Decrease quantity by 1
     *
     * @returns {void}
     */
    decreaseQuantity() {
      if (this.decreaseDisabled) {
        return;
      }
      this.updateQuantity(this.quantity - 1)
    },
  },
};
