/**
 * ```
 * @prettier
 * ```
 */

/**
 * Components
 */
import BasePhoneNumberInput from "@/components/base-phone-number-input/BasePhoneNumberInputComponent.vue";

/**
 * Checkout Contact Details Component
 *
 * Props:
 *   orderFields - {object} Order fields to be submitted
 */
export default {
  components: {
    BasePhoneNumberInput,
  },
  props: {
    orderFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        first_name: [
          {
            required: true,
            message: "First Name is required",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last Name is required",
          },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Valid Email is required",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: "Company is required",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Validates form
     *
     * @returns {void}
     */
    validateForm() {
      this.$refs.contactDetailsFormPhone.validateNumber();
      this.$refs.contactDetailsForm.validate((valid) => {
        if (!valid || !this.orderFields.phone) {
          return;
        }
        this.$emit("continue");
      });
    },

    /**
     * Called when phone number is changed, sets
     * the orderfield phone value
     *
     * @param {number} phone Phone number
     * @returns {void}
     */
    onPhoneChange(phone) {
      if (!phone) {
        return;
      }
      this.orderFields.phone = phone;
    },
  },
};
