export default {
  name: 'MoqMessageComponent',
  props: {
    prices: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      moq: 50,
    }  
  },
  methods: {
    /**
     * If MOQ message should be shown
     * 
     * @returns {boolean} true if the MOQ message should be shown
     */
    showMoqMessage() {
      if (process.env.VUE_APP_SHOW_MOQ_MESSAGE != 'true' || !this.prices) {
        return false
      }

      return this.prices.some((item) => item.minimumQuantity === this.moq);
    },
  },
}