// Components
import ProductModalImage from '@/components/product-modal-image/ProductModalImageComponent.vue';
import ProductModalQuantity from '@/components/product-modal-quantity/ProductModalQuantityComponent.vue';
import ProductModalPrice from '@/components/product-modal-price/ProductModalPriceComponent.vue';
import ProductModalDescription from '@/components/product-modal-description/ProductModalDescriptionComponent.vue';
import RequestSample from '@/components/request-sample/RequestSampleComponent.vue';
import VariationColor from '@/components/variation-color/VariationColorComponent.vue';
import VariationSelect from '@/components/variation-select/VariationSelectComponent.vue';
import VariationSize from '@/components/variation-size/VariationSizeComponent.vue';
import AdminProductVendorInfo from '@/components/admin-product-vendor-info/AdminProductVendorInfoComponent.vue';
import MoqMessage from '@/components/moq-message/MoqMessageComponent.vue';

// Mixins
import productMixin from '@/mixins/productMixin';
import cartMixin from '@/mixins/cartMixin';

// Constants
import { COLOR_VARIATION_ID } from '@/api/models/product';

export default {
  name: 'ProductModalComponent',
  mixins: [productMixin, cartMixin],
  components: {
    ProductModalImage,
    ProductModalQuantity,
    ProductModalPrice,
    ProductModalDescription,
    RequestSample,
    VariationColor,
    VariationSelect,
    VariationSize,
    AdminProductVendorInfo,
    MoqMessage,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object | null,
      required: true,
    },
    loadingProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
        price: false,
        image: false,
        addToCart: false,
      },
      quantity: this.getDefaultQuantity(),
      minQuantity: this.getMinQuantity(),
      selectedColor: {},
      selectedSizes: {},
      packagingError: false,
      userErrorMessage: '',
      COLOR_VARIATION_ID: COLOR_VARIATION_ID,
    };
  },
  watch: {
    open() {
      if (this.open) {
        this.onModalOpened();
      }
    },
  },
  methods: {
    /**
     * Returns if the product is being edited
     *
     * @returns {boolean} -> true if product is being edited
     */
    isEditing() {
      return this.$store.getters['product/getIsEditing'];
    },

    /**
     * Returns default quantity
     *
     * @returns {number} -> default quantity
     */
    getDefaultQuantity() {
      if (this.isEditing()) {
        return this.$store.getters['product/getSelectedProductQuantity'];
      }

      return this.inBulkMode() ? 100 : 1;
    },

    /**
     * Returns min quantity
     *
     * @returns {number} -> default min quantity
     */
    getMinQuantity() {
      if (!this.inBulkMode()) {
        return 1;
      }

      if (
        !this.product ||
        !this.product.enablePriceTable ||
        !this.product.prices
      ) {
        return 100;
      }

      // get lowest min quantity and return
      return this.product.prices.reduce(function(prev, curr) {
        return prev.minimumQuantity < curr.minimumQuantity ? prev : curr;
      }).minimumQuantity;
    },

    /**
     * Returns add to cart button text
     *
     * @returns {string} -> add to cart button text
     */
    getAddToCartButtonText() {
      if (this.inBulkMode()) {
        return this.isEditing() ? 'Update Swag' : 'Add Swag';
      }

      return this.isEditing() ? 'Update Product' : 'Add To Pack';
    },

    /**
     * Handles modal open, resets data
     *
     * @returns {void}
     */
    onModalOpened() {
      this.quantity = this.getDefaultQuantity();
      this.minQuantity = this.getMinQuantity();
      this.selectedColor = {};
      this.selectedSizes =  {};
      this.packagingError = false;
      this.userErrorMessage = '';
      this.loading = {
        product: false,
        price: false,
        image: false,
        addToCart: false,
      };
    },

    /**
     * Emits close event
     *
     * @returns {void}
     */
    onCloseModal() {
      if (this.isEditing) {
        this.$store.dispatch('product/changeIsEditingState', false);
      }
      this.removeProductParam();
      this.$emit('close');
    },

    /**
     * Gets price from product table due to quantity
     *
     * @param {number} quantity -> quantity of the selected product
     * @returns {void}
     */
    onQuantityChanged(quantity) {
      this.$store.dispatch('product/changeSelectedProductQuantity', quantity);
      let price = this.getPriceFromTable(
        this.product.enablePriceTable,
        this.product.prices,
        quantity,
        this.product.price
      );
      this.product.cPrice = Number(price);
    },

    /**
     * Handles selected sizes change
     *
     * @param {object} selectedSizes -> selected sizes
     * @returns {void}
     */
    onSelectedSizesChanged(selectedSizes) {
      this.quantity = selectedSizes.totalQuantity;
      this.selectedSizes = selectedSizes.sizesFormatted;

      this.onQuantityChanged(this.quantity);
    },

    /**
     * It changes product due to selected color
     *
     * @param {object} color -> color selected
     * @returns {void}
     */
    async onColorChanged(color) {
      this.loading.image = this.loading.price = true;
      try {
        await this.getVariationProductByAttributes(
          this.product,
          this.quantity,
          this.COLOR_VARIATION_ID,
          color.slug
        );
      } finally {
        this.loading.image = this.loading.price = false;
      }
    },

    /**
     * It changes product due to selected variation
     *
     * @param {object} variation -> variation selected
     * @returns {void}
     */
    async onVariationSelected(variation) {
      this.loading.price = true;
      try {
        await this.getVariationProductByAttributes(
          this.product,
          this.quantity,
          variation.id,
          variation.option.slug
        );
      } finally {
        this.loading.price = false;
      }
    },

    /**
     * Adds product to the cart
     *
     * @returns {void}
     */
    async addProductToCart() {
      this.loading.addToCart = true;
      if (!this.isQuantityValid()) {
        this.loading.addToCart = false;
        return;
      }

      if (!this.allowMultiplePackaging(this.product)) {
        this.packagingError = true;
        return;
      }

      await this.addToCart(this.product, this.quantity, this.selectedSizes);

      this.onCloseModal();
      this.loading.addToCart = false;
    },

    /**
     * Replace the current packaging with the newly selected one
     *
     * @returns {void}
     */
    async replacePackaging() {
      this.loading.addToCart = true;
      this.packagingError = false;

      const cart = await this.getCart();
      const productToReplace = cart.items.find((item) => {
        return item.product.isPackaging;
      });

      if (!productToReplace) {
        this.addProductToCart();
        return;
      }

      await this.removeCartItem(productToReplace.key);
      this.addProductToCart();
    },

    /**
     * Validates Quantity
     *
     * @returns {boolean} -> true if quantity is valid
     */
    isQuantityValid() {
      if (this.quantity < this.minQuantity) {
        this.userErrorMessage = `The minimum order quantity is ${this.minQuantity}`;
        return false;
      }

      this.userErrorMessage = '';
      return true;
    },
  },
};
