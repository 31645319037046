/**
 * ```
 * @prettier
 * ```
 */

/* Components */
import InputText from "primevue/inputtext";
import Button from "primevue/button";

/* Other */
import _ from "lodash";

/**
 * Product Supplier Info Component
 */
export default {
  components: {
    InputText,
    Button,
  },
  props: {
    categories: {
      default: [],
    },
    loadingCategories: {
      default: false,
    },
  },

  /**
   * @inheritdoc
   */
  data() {
    return {
      loading: true,
      originalCategories: [],
      search: "",
    };
  },

  watch: {
    /**
     * If loadingCategories is set to false,
     * then all categories & products have loaded in
     */
    loadingCategories: {
      handler(newVal) {
        if (newVal) {
          this.loading = true;
          return;
        }
        this.originalCategories = this.categories;
        this.loading = false;
      },
    },
  },

  created() {
    this.originalCategories = this.categories;
    this.loading = this.loadingCategories;
  },

  methods: {
    /**
     * Searches for products by name & sku.
     * Emits Filtered categories and products.
     *
     * @returns {void}
     */
    searchProducts: _.debounce(function (value) {
      this.loading = true;
      const searchValue = value.toLowerCase().trim();
      if (searchValue.length === 0) {
        this.clearSearch();
        return;
      }

      let categories = _.cloneDeep(this.originalCategories);

      // search products in each category
      // assign the category the filtered product list
      categories.forEach((category) => {
        const categoryProducts = category.products.filter((product) => {
          return (
            product.name.toLowerCase().includes(searchValue) ||
            product.sku.toLowerCase().includes(searchValue)
          );
        });
        category.products = categoryProducts;
      });

      // remove any category that has 0 products (no matches)
      categories = categories.filter((category) => {
        return category.products.length > 0;
      });

      // emit result
      this.$emit("result", categories);
      this.loading = false;
    }, 500),

    /**
     * Clears the search value and emits the orginal
     * categories
     *
     * @returns {void}
     */
    clearSearch() {
      this.loading = true;
      this.search = "";
      this.$emit("result", this.originalCategories);
      this.loading = false;
    },
  },
};
