/**
 * ```
 * @prettier
 * ```
 */

export default class User {
  constructor(id = 0, email = "", firstName = "", lastName = "", sites = []) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.sites = sites;
  }

  // Static variables
  static get SITE_TYPE_SHOP() {
    return "shop";
  }
  static get SITE_TYPE_PORTAL() {
    return "portal";
  }
  static get SITE_TYPE_HUB() {
    return "hub";
  }

  /**
   * Gets the users full name
   *
   * @returns {string} full name
   */
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * Gets the portals user can access
   *
   * @returns {Array} sites user can access
   */
  getPortals() {
    return this.sites.filter((site) => {
      return (
        site.site.type === User.SITE_TYPE_PORTAL && site.canSwitchTo === true
      );
    });
  }

  /**
   * Returns the user portal, if no search then the default portal
   * will be returned (first in portals array).
   *
   * @param {string} searchBy -> field to search
   * @param {string} searchValue -> search value
   * @param {boolean} returnDefault -> if search fails, return the default portal
   * @returns {object} portal
   */
  getPortal(searchBy = "", searchValue = "", returnDefault = false) {
    const portals = this.getPortals();

    if (!searchBy) {
      return portals[0];
    }

    const portal = portals.find((portal) => {
      return portal.site[searchBy] === searchValue;
    });

    if (!portal && returnDefault) {
      return portals[0];
    }

    return portal;
  }
}
