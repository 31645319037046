/**
 * Components
 */
import BaseAvatarMessageCard from '@/components/base-avatar-message-card/BaseAvatarMessageCardComponent.vue';
import BaseAvatarContactCard from '@/components/base-avatar-contact-card/BaseAvatarContactCardComponent.vue';

import VueLottiePlayer from "vue-lottie-player";

export default {
  components: {
    BaseAvatarMessageCard,
    BaseAvatarContactCard,
    VueLottiePlayer
  },
  data() {
    return {
        // used to get user name & email
        user: this.$store.getters["user/getUser"]
    }
  }
};
