/**
 * ```
 * @prettier
 * ```
 */

/* Components */
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Message from "primevue/message";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";

/* Styles */
import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

/**
 * Product Supplier Info Component
 */
export default {
  components: {
    Card,
    Skeleton,
    Message,
    DataTable,
    Column,
    InputNumber,
  },
  props: {
    productQuantity: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loadingProduct: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * @inheritdoc
   */
  data() {
    return {
      loading: {
        pricing: true,
      },
      dataToShow: {
        vendorName: "",
        vendorEmail: "",
        vendorPhone: "",
        productName: "",
        productCode: "",
        productLink: "",
        productSampleStock: "",
        productHsCode: "",
        productCountryOfOrigin: "",
        productSwaghutSku: "",
        productLeadTime: "",
      },
      vendorError: "",
      pricingError: "",
      pricing: [],
      quantity: 0,
    };
  },
  computed: {
    /**
     * It gives pack quantity
     *
     * @returns {number} Returns Pack quantity
     */
    packQuantity() {
      return this.$store.getters["cart/getPackQuantity"];
    },

    /**
     * It returns the ShowVendorDetailsState
     *
     * @returns {boolean}  showVendorDetails
     */
    showVendorDetails() {
      return this.$store.getters["admin/getShowVendorDetails"];
    },
  },
  created() {
    this.initializeDataToShow();

    this.quantity = this.productQuantity * this.packQuantity;
    this.getPricing(this.quantity);
  },
  watch: {
    product: {
      handler() {
        this.initializeDataToShow();
      },
      deep: true,
    },
    productQuantity: {
      handler(newVal) {
        this.quantity = newVal * this.packQuantity;
        this.getPricing(this.quantity);
      },
    },
  },
  methods: {
    /**
     * Builds the data to show
     *
     * @returns {void}
     **/
    initializeDataToShow() {
      if (!this.product) {
        return;
      }
      if (!this.product.parentData) {
        return;
      }
      this.dataToShow.productSwaghutSku = this.product.parentData.sku;
      this.dataToShow.productHsCode = this.product.parentData.hsCode;
      this.dataToShow.productCountryOfOrigin = this.product.parentData.countryOrigin;

      if (this.product.parentData.supplier) {
        this.dataToShow.vendorName = this.product.parentData.supplier.name;
        this.dataToShow.vendorEmail = this.product.parentData.supplier.email;
        this.dataToShow.vendorPhone = this.product.parentData.supplier.phone;
      }

      if (this.product.parentData.supplierInfo) {
        this.dataToShow.productName = this.product.parentData.supplierInfo.name;
        this.dataToShow.productCode = this.product.parentData.supplierInfo.code;
        this.dataToShow.productLink = this.product.parentData.supplierInfo.link;
        this.dataToShow.productSampleStock = this.product.parentData.supplierInfo.stockSample;
      }

      this.dataToShow.productLeadTime = this.product.getLeadTime();
    },

    /**
     * Gets the product pricing
     * to calculate the margin
     *
     * @param {number} quantity the product qty
     * @returns {void}
     */
    getPricing(quantity) {
      this.loading.pricing = true;

      let prices = this.product.prices;

      this.pricing = [
        {
          cost: 0,
          sell: 0,
          margin: 0,
        },
      ];

      for (let i = 0; i < prices.length; i++) {
        if (
          quantity >= parseFloat(prices[i].minimumQuantity) &&
          parseFloat(prices[i].maximumQuantity) >= quantity
        ) {
          this.pricing = [
            {
              cost: this.$currency.format(
                prices[i].tsgInternalPrice ? prices[i].tsgInternalPrice : 0
              ),
              sell: this.$currency.format(
                prices[i].customerPrice ? prices[i].customerPrice : 0
              ),
              margin: prices[i].margin,
              quantity,
            },
          ];
          break;
        }
      }

      this.loading.pricing = false;
    },

    /**
     * Returns a class based on the profit margin
     *
     * @param {number} margin profit margin
     * @returns {string} class name
     */
    marginClass(margin) {
      if (margin <= 29) {
        return "margin-red";
      }
      if (margin <= 39) {
        return "margin-orange";
      }
      if (margin <= 49) {
        return "margin-yellow";
      }
      return "margin-green";
    },

    /**
     * Handles the pricing quantity change
     * Gets new pricing.
     *
     * @param {number} quantity the product qty
     * @returns {void}
     */
    onPricingQtyChanged(quantity) {
      if (quantity < 1) {
        return;
      }
      this.getPricing(quantity);
    },
  },
};
