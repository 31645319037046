/**
 * ```
 * @prettier
 * ```
 */

import AbstractApi from "../abstract-api";
import axios from "axios";

const abstractApi = new AbstractApi();
const wooCommerceRef = abstractApi.getWoocommerceRef();
const wpRef = abstractApi.getWpRef();

const OrdersService = {
  /**
   * Get all orders according to queryParams
   *
   * @param {object} queryParams Params with Search string/SortBy/OrderBy etc
   * @example queryParams: {
   *            page: {number}, Current page of the collection. Default is 1.
   *            per_page: {number}, Number of items to be retrieved (Default:10)
   *            offset: {number}, Offset the result set by a specific number of items.
   *            status {string}, Limit result set to orders assigned a specific status.
   *                             Options: any, pending, processing, on-hold, completed,
   *                             cancelled, refunded, failed and trash. Default is any.
   *            customer {number}, Limit result set to orders assigned a specific customer.
   *            product { number}, Limit result set to orders assigned a specific product.
   *            search: {string}, Limit results to those matching a string.
   *            exclude: {array}, Ensure result set excludes specific IDs.
   *            include: {array}, Limit result set to specific ids
   *            order {string}, Order sort attribute ascending or descending. Options: asc and desc. Default is desc.
   *            orderby: {string}, Sort collection by object attribute. Options: date, id, include, title and slug. Default is date.
   *            sku: {string}, Limit result set to products with a specific SKU.
   *          }
   * @returns {Promise} Returns index order promise
   */
  index(queryParams = {}) {
    return wooCommerceRef.get("orders", queryParams).then((response) => {
      return response;
    });
  },

  /**
   * Retrieve an order by id
   *
   * @param {number} id Order ID to be shown
   * @returns {Promise} Orders show promise
   */
  show(id) {
    return wooCommerceRef.get(`orders/${id}`).then((response) => {
      return response.data;
    });
  },

  /**
   * It creates a new order with the data passed
   *
   * @param {object} queryParams Params with order's data
   * @returns {Promise} Orders store promise
   */
  store(queryParams = {}) {
    const apiUrl = `${wpRef}wc-proxy/v3/orders`;
    return axios.post(apiUrl, queryParams).then((response) => {
      return response;
    });
  },

  /**
   * It creates a new order with cart's information
   *
   * @returns {Promise} Returns store cart order promise
   */
  storeCart() {
    const apiUrl = `${wpRef}wc/store/checkout`;
    return axios.get(apiUrl).then((response) => {
      return response;
    });
  },

  /**
   * Update an Order with the specified ID
   *
   * @param {number} id Order ID
   * @param {object} queryParams Params with order's data
   * @returns {Promise} Orders update promise
   */
  update(id, queryParams) {
    return wooCommerceRef.put(`orders/${id}`, queryParams).then((response) => {
      return response;
    });
  },

  /**
   * Upload a file to an Order
   *
   * @param {number} id Order ID
   * @param {object} queryParams Params with order's file
   * @returns {Promise} Returns upload file promise
   */
  uploadFile(id, queryParams) {
    const apiUrl = `${wpRef}shop-api/v1/orders/${id}/uploads`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    };
    return axios.post(apiUrl, queryParams, config).then((response) => {
      return response;
    });
  },

  /**
   * It creates a new order note for a specific order
   *
   * @param {number} id Order ID
   * @param {object} queryParams Params with order note data
   * @returns {Promise} Orders store promise
   */
  createNote(id, queryParams) {
    const apiUrl = `${wpRef}wc-proxy/v3/orders/${id}/notes`;
    return axios.post(apiUrl, queryParams).then((response) => {
      return response;
    });
  },
};

export default OrdersService;
