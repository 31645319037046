import { render, staticRenderFns } from "./ProductCardComponent.html?vue&type=template&id=14e3bbcb&scoped=true&"
import script from "./ProductCardComponent.js?vue&type=script&lang=js&"
export * from "./ProductCardComponent.js?vue&type=script&lang=js&"
import style0 from "./ProductCardComponent.scss?vue&type=style&index=0&id=14e3bbcb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14e3bbcb",
  null
  
)

export default component.exports