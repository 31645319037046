// Mixins
import CartMixin from "@/mixins/cartMixin.js";
import ProductMixin from "@/mixins/productMixin.js";

// Components
import VueLottiePlayer from "vue-lottie-player";

export default {
  mixins: [CartMixin, ProductMixin],
  components: {
    VueLottiePlayer,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      quickAddLoading: false,
      showQuickAddSuccess: false,
    };
  },
  methods: {
    /**
     * Adds default product variation to the cart
     *
     * @param {object} product - the product to add
     * @returns {void}
     */
    async quickAdd(product) {
      
      //updating attributes
      product.setVariationsValues(product, false);
       
      // if in bulk mode & product has sizing, show product details as we need to select the size
      if (this.inBulkMode() && product.hasSizing) {
        this.$emit("showProduct", product);
        return;
      }
      try {
        this.quickAddLoading = true;
        const quantity = this.inBulkMode() ? product.defaultQuantity : 1;

        const addedToCart = await this.addToCart(product, quantity);
        if (addedToCart) {
          this.quickAddSuccessful();
        }
      } finally {
        this.quickAddLoading = false;
      }
    },

    /**
     * Shows a tick icon for 2.5 seconds to indicate that the product was added to the cart
     *
     * @returns {void}
     **/
    quickAddSuccessful() {
      this.showQuickAddSuccess = true;
      setTimeout(() => {
        this.showQuickAddSuccess = false;
      }, 2500);
    },
  },
};
