/**
 * ```
 * @prettier
 * ```
 */

export default {
  props: {
    password: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      status: "none",
      length: false,
      lowercase: false,
      uppercase: false,
      special: false,
      digit: false,
    };
  },

  watch: {
    /**
     * Watches password prop, calls checkPasswordStrength()
     *
     * @returns {void}
     */
    password() {
      this.status = this.checkPasswordStrength();
    },
  },

  methods: {
    /**
     * Checks the password strength
     *
     * @returns {string} status
     */
    checkPasswordStrength() {
      // At least one lowercase letter (?=.*[a-z]), one uppercase letter (?=.*[A-Z]), one digit (?=.*[0-9]),
      // one special character (?=.*[^A-Za-z0-9]), and is at least eight characters long(?=.{8,}).
      const requireOneLowercase = /(?=.*[a-z])/;
      const requireOneUppercase = /(?=.*[A-Z])/;
      const requireOneDigit = /(?=.*[0-9])/;
      const requireOneSpecial = /(?=.*[^A-Za-z0-9])/;
      const requireEightChars = /(?=.{8,})/;

      this.length = false;
      this.lowercase = false;
      this.uppercase = false;
      this.special = false;
      this.digit = false;

      let progress = 0;
      if (requireEightChars.test(this.password)) {
        this.length = true;
        progress++;
      }
      if (requireOneUppercase.test(this.password)) {
        this.uppercase = true;
        progress++;
      }
      if (requireOneLowercase.test(this.password)) {
        this.lowercase = true;
        progress++;
      }
      if (requireOneDigit.test(this.password)) {
        this.digit = true;
        progress++;
      }
      if (requireOneSpecial.test(this.password)) {
        this.special = true;
        progress++;
      }

      const progressPercentage = (progress / 5) * 100;
      let status = "none";

      if (progressPercentage > 0) {
        status = "weak";
      }
      if (progressPercentage > 40) {
        status = "medium";
      }
      if (progressPercentage === 100) {
        status = "strong";
      }

      this.$emit("getStrength", status);
      this.$emit("valid", progressPercentage === 100);
      return status;
    },
  },
};
