/**
 * ```
 * @prettier
 * ```
 *
 * Uses Prime Vue - https://www.primefaces.org/primevue-v2
 * Icons - https://www.primefaces.org/primevue-v2/#/icons
 */

/* Components */
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import Checkbox from "primevue/checkbox";
import Message from "primevue/message";
import InlineMessage from "primevue/inlinemessage";
import Divider from "primevue/divider";

/* Styles */
import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

/* Services */
import ProductsService from "@/api/services/resources/products";

/* Mixins */
import ProductMixin from "@/mixins/productMixin.js";

import he from "he";
import { ExportToCsv } from "export-to-csv";

export default {
  mixins: [ProductMixin],
  components: {
    Button,
    Sidebar,
    Checkbox,
    Message,
    InlineMessage,
    Divider,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
        export: false,
      },
      sidebarVisible: false,
      error: "",
      checkboxFields: [
        "Product Name",
        "Supplier Product Name",
        "Supplier Name",
        "Supplier Code",
        "Supplier Link",
        "Supplier Email",
        "Supplier Phone",
        "HS Code",
        "Country of Origin",
        "Product SKU",
        "Customer Price",
        "Internal Price",
        "Product Attributes",
      ],
      selectedFields: [],
      selectedAll: false,
    };
  },
  watch: {
    /**
     * Listen to open prop changes and set the sidebar visible accordingly.
     *
     * @param {boolean} newValue - The new value of the open prop.
     * @returns {void}
     */
    open(newValue) {
      this.sidebarVisible = newValue;
    },

    /**
     * Listen to the selected fields and set the selectedAll checkbox accordingly.
     * If all the fields are selected, then set the selectedAll checkbox to true.
     *
     * @param {boolean} fields - The selected fields.
     * @returns {void}
     */
    selectedFields(fields) {
      if (fields.length === this.checkboxFields.length) {
        this.selectedAll = true;
        return;
      }
      this.selectedAll = false;
    },
  },
  computed: {
    /**
     * It computes cart items
     *
     * @returns {Array} Array of items from the cart
     */
    cartItems() {
      return this.$store.getters["cart/getCart"].items || [];
    },
  },
  methods: {
    /**
     * Emitted when the sidebar is toggled.
     *
     * @returns {void}
     */
    onSidebarClosed() {
      this.error = "";
      this.$emit("onClosed", false);
    },

    /**
     * Selects all the fields or deselects all the fields.
     *
     * @returns {void}
     */
    toggleAllFields() {
      if (!this.selectedAll) {
        this.selectedFields = [];
        return;
      }
      this.selectedFields = this.checkboxFields;
    },

    /**
     * Exports selected products to csv
     *
     * @returns {void}
     */
    async exportCSV() {
      try {
        if (this.selectedFields.length < 1) {
          this.error = "Please select at least one field";
          return;
        }
        this.error = "";
        this.loading.export = true;

        // build csv data
        const data = await this.buildCSV(this.selectedFields, this.cartItems);

        // create csv file
        const csvExporter = new ExportToCsv({
          filename: "swaghut-products",
          useKeysAsHeaders: true,
        });
        csvExporter.generateCsv(data);
      } catch (error) {
        console.error(error);
        this.error = "Something went wrong";
      }

      this.loading.export = false;
    },

    /**
     * Builds the CSV file
     * see - https://github.com/alexcaza/export-to-csv
     *
     * @param {string[]} fields - selected fields
     * @param {object[]} cartItems - cart items
     * @returns {object} csv data
     */
    async buildCSV(fields, cartItems) {
      const content = [];

      for (const cartItem of cartItems) {
        let row = {};
        const product = await ProductsService.getProduct(cartItem.product.id);

        for (const field of fields) {
          switch (field) {
            case "Product Name":
              row[field] = product.name;
              break;
            case "Supplier Product Name":
              row[field] = product.supplierInfo.name;
              break;
            case "Supplier Name":
              row[field] = product.supplier.name;
              break;
            case "Supplier Code":
              row[field] = product.supplierInfo.code;
              break;
            case "Supplier Link":
              row[field] = product.supplierInfo.link;
              break;
            case "Supplier Email":
              row[field] = product.supplier.email;
              break;
            case "Supplier Phone":
              row[field] = product.supplier.phone;
              break;
            case "HS Code":
              row[field] = product.hsCode;
              break;
            case "Country of Origin":
              row[field] = product.countryOrigin;
              break;
            case "Product SKU":
              row[field] = product.sku;
              break;
            case "Customer Price":
              row[field] = this.$options.filters.currency(
                this.getPriceFromTable(
                  product.enablePriceTable,
                  product.prices,
                  cartItem.quantityPerPack,
                  product.price
                )
              );
              break;
            case "Internal Price":
              row[field] = this.$options.filters.currency(
                this.getPriceFromTable(
                  product.enablePriceTable,
                  product.prices,
                  cartItem.quantityPerPack,
                  product.price,
                  true
                )
              );
              break;
            case "Product Attributes":
              row = Object.assign(
                row,
                this.getProductAttributesForCsv(product)
              );
              break;
            default:
              break;
          }
        }
        content.push(row);
      }

      return content;
    },

    /**
     * Formats the product attributes for CSV
     *
     * @param {object} product product
     * @returns {object} formatted attributes
     */
    getProductAttributesForCsv(product) {
      const attributes = product.attributes;
      let formattedAttributes = "";

      for (const attribute of attributes) {
        const attributeValue = attribute.options[0].name;
        formattedAttributes += `${attribute.name} - ${he.decode(
          attributeValue
        )},  `;
      }

      return { "Product Attributes": formattedAttributes };
    },
  },
};
