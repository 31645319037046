export default {
  props: ["options"],
  data: () => ({
    observer: null,
  }),
  mounted() {
    const observerOptions =
      this.options || {
        root: null,
        rootMargin: "100px",
        thredhold: 0.5,
      };
    
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    }, observerOptions);

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  }
};
