/**
 * ```
 * @prettier
 * ```
 */

import FileType from "file-type/browser";

export default class FileService {
  /**
   * Cast a serialized-based64 image string to a File object
   *
   * @param {string} blob base64 serialized image
   * @param {string} name file name
   * @returns {File} Converted file
   */
  base64ImageToFile(blob, name) {
    const arr = blob.split(",");
    const mime = arr[0].match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1;
    }

    return new File([u8arr], name, { type: mime });
  }

  /**
   * Download a file from its URL
   *
   * @param {string} fileUrl File URL to be downloaded
   * @returns {Promise} Returns file result
   */
  async download(fileUrl) {
    const data = await fetch(fileUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      resolve(blob);
    });
  }

  /**
   * Get te file name based in the file type
   *
   * @param {object} blob Blob file
   * @returns {Promise} Returns transformed file name
   */
  async getFileName(blob) {
    return FileType.fromBlob(blob).then((fileType) => {
      return `file_${new Date().getTime()}.${fileType.ext}`;
    });
  }
}
