/**
 * ```
 * @prettier
 * ```
 */

export default class ProductAttribute {
  constructor(
    id = null,
    name = null,
    options = null,
    selected = [],
    position = null,
    variation = false,
    visible = false
  ) {
    this.id = id;
    this.name = name;
    this.options = options;
    this.position = position;
    this.variation = variation;
    this.visible = visible;
    this.selectedItem = selected;
  }

  /**
   * Gets the option list values to be used in a select input
   *
   * @returns {Array} The options list values.
   */
  getOptionValues() {
    if (!this.options) {
      return [];
    }

    return this.options.map((option) => {
      return { value: option.slug, text: option.name };
    });
  }

  /**
   * Returns the item value to be used to create a product variations.
   *
   * @returns {object} The serialized item value.
   */
  get itemValue() {
    return {
      value: this.option,
      attribute: this.name,
    };
  }
}
