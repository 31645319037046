/**
 * ```
 * @prettier
 * ```
 */

import App from "./App.vue";
import Vue from "vue";

import store from "./store";
import vueCurrencyFilter from "vue-currency-filter";
import VueObserveVisibility from "vue-observe-visibility";
import VueScrollTo from "vue-scrollto";
import Antd from "ant-design-vue";
import VueMoment from "vue-moment";
import "ant-design-vue/dist/antd.css";
import LottiePlayer from "lottie-player-vue";
import VueGtag from "vue-gtag";

/* custom css  */
import "./assets/ant-variables.less";
import "./assets/fonts.scss";
import "./assets/swaghut.scss";
import "./assets/swaghut-ant.scss";
import "./assets/global.scss";

/**
 * Router
 */
import router from "./router";

/**
 * Plugins
 */
import currencyFilters from "./plugins/vue-currency-filter.js";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

import ZoomOnHover from "vue-zoom-on-hover";

Vue.use(ZoomOnHover);
Vue.use(vueCurrencyFilter, currencyFilters);
Vue.use(VueObserveVisibility);
Vue.use(VueMoment);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 300,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.use(Antd);
Vue.use(LottiePlayer);
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_TAG },
  enabled: process.env.VUE_APP_DEV !== "true",
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
