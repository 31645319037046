/**
 * ```
 * @prettier
 * ```
 */

import ProductAttribute from "./product-attribute.js";
import ProductPricingRule from "./product-pricing-rule.js";

// Variation IDs
export const COLOR_VARIATION_ID = "pa_available-colors";

// Attribute IDs
export const SIZE_ATTRIBUTE_ID = "pa_size";
export const LEAD_TIME_ATTRIBUTE_ID = "Leadtime";

export default class Product {
  constructor(
    attributes = [],
    categories = [],
    defaultAttributes = [],
    description = "",
    dimensions = {},
    id = "",
    image = "",
    metaData = [],
    name = "",
    onSale = false,
    parentId = 0,
    price = null,
    shortDescription = "",
    sku = "",
    slug = "",
    type = "",
    variations = [],
    parentData = {},
    prices = [],
    enablePriceTable = false
  ) {
    this.attributes = attributes;
    this.categories = categories;
    this.defaultAttributes = defaultAttributes;
    this.description = description;
    this.dimensions = dimensions;
    this.id = id;
    this.image = image;
    this.metaData = metaData;
    this.name = name;
    this.onSale = onSale;
    this.parentId = parentId;
    this.price = price;
    this.shortDescription = shortDescription;
    this.sku = sku;
    this.slug = slug;
    this.type = type;
    this.variations = variations;
    this.parentData = parentData;
    this.prices = prices;
    this.enablePriceTable = enablePriceTable;
  }

  /**
   * Parse the attrbutes value to a List of ProductAttributes
   *
   * @returns {Array} List of ProductAttributes
   */
  getAttibutes() {
    if (!this.attributes) {
      return [];
    }

    let attributes = this.attributes.map((attribute) => {
      return Object.assign(new ProductAttribute(), attribute);
    });

    return attributes;
  }

  /**
   * Parse the variations value to a List of ProductAttributes
   *
   * @returns {Array} List of ProductAttributes
   */
  getVariations() {
    if (!this.variations) {
      return [];
    }

    let variations = this.variations.map((attribute) => {
      return Object.assign(new ProductAttribute(), attribute);
    });

    return variations;
  }

  /**
   * The final price of the product taking into account
   * the current organization
   *
   * @returns {number} The price amount
   */
  getPrice() {
    return this.price;
  }

  /**
   * Return the price in the matrix pricing when
   * the quantity matches with the pricing rules (from - to)
   *
   * @param {number} quantity The quantity value
   * @returns {number} The final price amount
   */
  getPricePerQuantity(quantity) {
    quantity = Number(quantity);
    const pricingRules = this.pricingRules;

    if (!pricingRules || pricingRules.length === 0) {
      return 0;
    }

    let rule = pricingRules.find((rule) => {
      if (rule.from && rule.to) {
        return quantity >= rule.from && quantity <= rule.to;
      }

      return quantity == rule.to;
    });

    if (!rule) {
      return 0;
    }

    return rule.amount;
  }

  /**
   * Checks if the product belongs to a category
   *
   * @param {string} categorySlug The category slug
   * @returns {boolean} true if the product belongs to the category
   */
  hasCategory(categorySlug) {
    return this.categories.some((category) => {
      return category.slug === categorySlug;
    });
  }

  /**
   *It sets variations
   *
   * @param {object} productVariation product
   * @param {boolean} isNew attributes are new
   * @returns {void}
   */
  setVariationsValues(productVariation, isNew = true) {
    if (isNew) {
      this.attributes = [];
      this.variations = [];
    }
    productVariation.parentData.attributes.forEach((item) => {
      let options = [];
      let selectedOption = [];
      item.options.forEach((optionParent) => {
        options.push({ slug: optionParent.slug, name: optionParent.name });
      });

      productVariation.attributes.forEach((option) => {
        if (item["slug"] == option.slug) {
          option.options.forEach((optionInner) => {
            selectedOption.push(optionInner.slug);
          });
        }
      });
      if (item.isUsedForVariations == true) {
        this.variations.push(
          new ProductAttribute(
            item["slug"],
            item["name"],
            options,
            selectedOption,
            null,
            item.isUsedForVariations
          )
        );
      } else {
        this.attributes.push(
          new ProductAttribute(
            item["slug"],
            item["name"],
            options,
            selectedOption,
            null,
            item.isUsedForVariations
          )
        );
      }
    });
  }

  /**
   * sets the price
   *
   * @param {number} customPrice to be price
   */
  setPrice(customPrice) {
    this.price = customPrice;
  }

  /**
   * sets id
   *
   * @param {number} id id
   */
  setId(id) {
    this.id = id;
  }

  /**
   * sets parent Data
   *
   * @param {object} parent to be price
   */
  setParentData(parent) {
    this.parentData = parent;
  }

  /**
   * sets image
   *
   * @param {string} image image
   */
  setImage(image) {
    this.image = image;
  }

  /**
   * sets description
   *
   * @param {string} description description
   */
  setDescription(description) {
    this.description = description;
  }

  /**
   * Returns the fixed quantities to be selected by the product
   *
   * @returns {Array} List of fixed quantities
   */
  get fixedQuantities() {
    if (!this.custom_options || !this.custom_options.fixed_quantities) {
      return [];
    }

    return this.custom_options.fixed_quantities[0];
  }

  /**
   * Returns true if the product allows range pricing
   *
   * @returns {boolean} Has range pricing
   */
  get hasRangePricing() {
    const pricingRules = this.pricingRules;

    if (!pricingRules || pricingRules.length === 0) {
      return false;
    }

    let rule = pricingRules.shift();

    if (!rule) {
      return false;
    }

    return Boolean(rule.to && rule.from);
  }

  /**
   * Returns true if the product has sizing
   *
   * @returns {boolean} Has range pricing
   */
  get hasSizing() {
    return this.attributes.some((attribute) => {
      return attribute.id === "pa_size";
    });
  }

  /**
   * Returns true if the product is a variation.
   *
   * @returns {boolean} true if the product require an upload file.
   */
  get hasUploadFilesRules() {
    return (
      this.custom_options &&
      this.custom_options.upload_files &&
      this.custom_options.upload_files.rules &&
      this.custom_options.upload_files.rules.length >= 1
    );
  }

  /**
   * Returns true if the product has a matrix pricing
   *
   * @returns {boolean} Is matrix pricing
   */
  get isMatrixPricing() {
    const pricingRules = this.pricingRules;

    if (!pricingRules || pricingRules.length === 0) {
      return false;
    }

    return true;
  }

  /**
   * Returns true if the product is a variation.
   *
   * @returns {boolean} true if the product is a variation.
   */
  get isVariable() {
    return this.type === "variation";
  }

  /**
   * Returns the max quantity availiable for this product
   *
   * @returns {number} Max quantity
   */
  get maxQuantity() {
    const pricingRules = this.pricingRules;
    if (!pricingRules || pricingRules.length === 0) {
      if (this.custom_options && this.custom_options.quantity_max) {
        return this.custom_options.quantity_max;
      }

      return null;
    }

    let rule = pricingRules.pop();

    if (rule && !rule.to) {
      rule = pricingRules.pop();
    }

    return rule && rule.to ? rule.to : null;
  }

  /**
   * Returns the min quantity availiable for this product
   *
   * @returns {number} Min quantity
   */
  get minQuantity() {
    const pricingRules = this.pricingRules;

    if (!pricingRules || pricingRules.length === 0) {
      if (this.custom_options && this.custom_options.quantity_min) {
        return this.custom_options.quantity_min;
      }

      return 1;
    }

    const rule = pricingRules.shift();

    return rule && rule.from ? rule.from : 1;
  }

  /**
   * Return the list of the Pricing Rules
   *
   * @returns {Array<ProductPricingRule>} List of the Pricing Rules
   */
  get pricingRules() {
    if (!this.custom_options || !this.custom_options.pricing_rules) {
      return [];
    }

    const fixedQuantities = this.fixedQuantities;

    if (!fixedQuantities) {
      return ProductPricingRule.fromArray(this.custom_options.pricing_rules);
    }

    let rules = this.custom_options.pricing_rules.map((rule) => {
      rule.from = null;
      return rule;
    });

    rules.pop(); // latest item is not necessary

    return ProductPricingRule.fromArray(rules);
  }

  /**
   * Serialized all the variation item values.
   *
   * @returns {Array} The variation item values.
   */
  get variationItemValues() {
    const attributes = this.variationsAttributes;

    if (!attributes || attributes.length === 0) {
      return [];
    }

    return attributes.map((attribute) => {
      attribute = Object.assign(new ProductAttribute(), attribute);
      return attribute.itemValue;
    });
  }

  /**
   * Get just the attributes marked as variation.
   *
   * @returns {Array} List of Attributes
   */
  get variationsAttributes() {
    if (!this.variations) {
      return [];
    }

    const attributes = this.variations.filter((attribute) => {
      return attribute.variation;
    });

    return attributes;
  }

  /**
   * Return true when all variation attributes has a value.
   *
   * @returns {boolean} True if has value
   */
  get variationsHasValues() {
    const attributes = this.variationsAttributes;

    for (let attribute of attributes) {
      if (!attribute.option) {
        return false;
      }
    }

    return true;
  }

  /**
   * Formats the attribute name and returns
   *
   * @param {string} attribute -> attribute string can be either
   * 'available-colors', 'pa_available-colors' or 'available colors'
   * @returns {string} {
   * shortSlug: 'available-colors'
   * fullSlug: 'pa_available-colors'
   * displayName: 'Available colors'
   * }
   */
  static formatAttributeName(attribute) {
    const result = {
      shortSlug: "",
      fullSlug: "",
      displayName: "",
    };
    result.shortSlug = attribute
      .trim()
      .toLowerCase()
      .replace("attribute_", "")
      .replace("pa_", "")
      .replace(/ /g, "-");
    result.fullSlug = "pa_" + result.shortSlug;
    result.displayName = result.shortSlug.replace(/-/g, " ");

    return result;
  }

  // NEW -> G.L

  /**
   * Checks if product has the color attribute
   *
   * @returns {boolean} True if color attribute
   */
  hasColorVariation() {
    return this.variations.some((variation) => {
      return variation.id === COLOR_VARIATION_ID;
    });
  }

  /**
   * Returns the color variation
   *
   * @returns {object} colour variation
   */
  getColorVariation() {
    return (
      this.variations.find((variation) => {
        return variation.id === COLOR_VARIATION_ID;
      }) || null
    );
  }

  /**
   * Checks if product has the size attribute
   *
   * @returns {boolean} True if size attribute
   */
  hasSizeVariation() {
    return this.attributes.some((attribute) => {
      return attribute.id === SIZE_ATTRIBUTE_ID;
    });
  }

  /**
   * Looks for the attribute with the given id
   *
   * @returns {object} attribute
   */
  getSizeVariation() {
    return (
      this.attributes.find((attribute) => {
        return attribute.id === SIZE_ATTRIBUTE_ID;
      }) || null
    );
  }

  /**
   * Returns the product lead time
   *
   * @returns {string} lead time
   **/
  getLeadTime() {
    const leadTime = this.attributes.find((attribute) => {
      return attribute.id === LEAD_TIME_ATTRIBUTE_ID;
    });

    if (!leadTime) {
      return "";
    }

    return leadTime.options[0].name || "";
  }

  /**
   * Returns the product image url or a placeholder
   *
   * @returns {string} image url
   */
  getImageUrl() {
    return this.image || this.getPlaceholderImageUrl();
  }

  /**
   * Returns the product image placeholder
   *
   * @returns {string} image url
   */
  getPlaceholderImageUrl() {
    return require("@/assets/images/stock-images/placeholder.png");
  }
}
