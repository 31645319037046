export default {
  name: 'ProductModalImageComponent',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageExists: false,
      placeholder: require("@/assets/images/stock-images/placeholder.png"),
    };
  },
  watch: {
    imageUrl() {
      this.checkImageExists();
    },
  },
  created() {
    this.checkImageExists();
  },
  computed : {
    /**
     * Returns the image to display
     * 
     * @returns {string} Image to display
     */
    imageToDisplay() {
      return this.imageExists ? this.imageUrl : this.placeholder;
    },
  },
  methods: {
    /**
     * Checks if the image exists
     * 
     * @returns {void}
     */
    checkImageExists() {
      const image = new Image();
      image.onload = () => {
        this.imageExists = true;
      }
      image.onerror = () => {
       this.imageExists = false;
      }

      image.src = this.imageUrl;
    },
  },
}