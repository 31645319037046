const SIZES = ['s', 'm', 'l'];

/**
 * Sorts the sizes in the correct order
 *
 * @param {string[]} sizesToSort -> sizes to sort
 * @param {string} key -> key to sort by, leave null if no key
 * @returns {string[]} sorted sizes
 */
function sortSizes(sizesToSort, key = null){
  sizesToSort.sort((a, b) => {
    const aWeight = getSizeWeight(key ? a[key] : a);
    const bWeight = getSizeWeight(key ? b[key] : b);
    // a is greater then b
    if (aWeight > bWeight) {
      return 1;
    }
    // a is less then b
    if (aWeight < bWeight) {
      return -1;
    }
    return 0;
  });

  return sizesToSort;
}

/**
 * Gives a weight to a size based on the size and the number of x's
 * e.g. 2XL -> 3, XL -> 2, L -> 1, S -> -1
 *
 * @param {string} size -> size to get weight of
 * @returns {number} weight
 */
function getSizeWeight(size) {
  size = size.toLowerCase();

  // Checks if multiplier e.g. 4XL -> 4, XL -> 1
  const xMultiples = parseInt(size) || 1;

  // How many x's are there, this includes the multipliers e.g 2XL => 2, 2XXL -> 4
  const xOccurrences = (size.match(/x/g) || []).length * xMultiples;

  // Gets the single size e.g. s, m, l
  const singleSize = size.charAt(size.length - 1);

  // index 0 is always gonna be lowest value, there return a negative score
  if (singleSize === SIZES[0]) {
    return -Math.abs(xOccurrences);
  }
  return SIZES.indexOf(singleSize) + xOccurrences;
}

export { sortSizes };
