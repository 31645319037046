// Components
import BaseDotsLoader from '@/components/base-dots-loader/BaseDotsLoaderComponent.vue';

export default {
  name: 'ProductModalPriceComponent',
  components: {
    BaseDotsLoader,
  },
  props: {
    price: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bulkMode: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    minQuantity: {
      type: Number,
      default: 1,
    },
  },
  methods : {
    /**
     * Get the price text
     * 
     * @returns {string} -> The price text
     */
    getPrice() {
      return this.bulkMode ? this.price : this.price * this.quantity ;
    },

    /**
     * Get the price sub text
     * 
     * @returns {string} -> The price sub text
     */
    getPriceSubText() {
      let text = `${this.$options.filters.currency(this.price)} per item `;

      if (this.bulkMode) {
        text += `/ ${this.quantity} Items`;
      } else {
        text += `/ ${this.$store.getters["cart/getPackQuantity"]} Packs`;
      }

      return text;
    }
  },
};
