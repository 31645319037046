/**
 * ```
 * @prettier
 * ```
 *
 * Uses Prime Vue - https://www.primefaces.org/primevue-v2
 * Icons - https://www.primefaces.org/primevue-v2/#/icons
 */

/* Components */
import TieredMenu from "primevue/tieredmenu";
import Button from "primevue/button";
import AdminExportCartProductsComponent from "@/components/admin-export-cart-products/AdminExportCartProductsComponent.vue";
import AdminExportPresentationComponent from "@/components/admin-export-presentation/AdminExportPresentationComponent.vue";

/* Styles */
import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

/* Services */
import UserService from "../../api/services/resources/user";

export default {
  components: {
    TieredMenu,
    Button,
    AdminExportCartProductsComponent,
    AdminExportPresentationComponent,
  },
  data() {
    return {
      showMenu: false,
      exportCartProductsVisible: false,
      exportPresentationVisible: false,
      items: [
        {
          label: "Show Vendor Details",
          icon: "pi pi-fw pi-eye",
          command: () => {
            this.toggleVendorDetails();
          },
          visible: () => {
            return !this.showVendorDetails;
          },
        },
        {
          label: "Hide Vendor Details",
          icon: "pi pi-fw pi-eye-slash",
          command: () => {
            this.toggleVendorDetails();
          },
          visible: () => {
            return this.showVendorDetails;
          },
        },
        {
          label: "Export",
          icon: "pi pi-fw pi-download",
          items: [
            {
              label: "Product Data",
              icon: "pi pi-fw pi-file-excel",
              command: () => {
                this.toggleExportCartProducts();
              },
            },
            {
              label: "Presentation",
              icon: "pi pi-fw pi-file-pdf",
              command: () => {
                this.toggleExportPresentation();
              },
            },
          ],
        },
        {
          separator: true,
        },
        {
          label: "Settings",
          icon: "pi pi-fw pi-cog",
          items: [
            {
              label: "Menu Top",
              icon: "pi pi-fw pi-arrow-up",
              command: () => {
                this.changeMenuPostion("top");
              },
            },
            {
              label: "Menu Bottom",
              icon: "pi pi-fw pi-arrow-down",
              command: () => {
                this.changeMenuPostion("bottom");
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    /**
     * It returns the ShowVendorDetailsState
     *
     * @returns {boolean}  showVendorDetails
     */
    showVendorDetails() {
      return this.$store.getters["admin/getShowVendorDetails"];
    },

    /**
     * It returns the AdminMenuPosition
     *
     * @returns {string}  AdminMenuPosition
     */
    getAdminMenuPosition() {
      return this.$store.getters["admin/getAdminMenuPosition"];
    },
  },
  created() {
    this.checkAdminMenuCapability();
  },
  methods: {
    /**
     * Checks if the user has access to the admin menu,
     * if So it shows the menu
     *
     * @returns {void}
     */
    checkAdminMenuCapability() {
      this.showMenu = false;
      UserService.can("swaghut_cap_access_admin_menu")
        .then((response) => {
          if (response.data.hasCap === true) {
            this.getSavedSettings();
            this.showMenu = true;
            this.$store.dispatch("admin/changeCanAccessAdminMenu", true);
          }
        })
        .catch(() => {
          this.showMenu = false;
        });
    },

    /**
     * Gets saved settings from local storage
     *
     * @returns {void}
     */
    getSavedSettings() {
      // sets saved menu position from local storage
      const menuPosition = localStorage.getItem("admin-menu-position");
      if (menuPosition) {
        this.changeMenuPostion(menuPosition);
      }

      // sets saved show vendor details from local storage
      if (
        localStorage.getItem("admin-show-vendor-details") === "true" &&
        !this.showVendorDetails
      ) {
        this.toggleVendorDetails();
      }
    },

    /**
     * Toggle the admin menu
     *
     * @param {Event} event - button
     * @returns {void}
     */
    toggleMenu(event) {
      this.$refs.adminMenu.toggle(event);
    },

    /**
     * Changes the menu postion
     *
     * @param {string} position The position to change to {top | bottom}
     * @returns {void}
     */
    changeMenuPostion(position = "top") {
      localStorage.setItem("admin-menu-position", position);
      this.$store.dispatch("admin/changeAdminMenuPosition", position);
    },

    /**
     * Toogles vendor details
     *
     * @returns {void}
     */
    toggleVendorDetails() {
      this.$store.dispatch(
        "admin/changeShowVendorDetailsState",
        !this.showVendorDetails
      );
      localStorage.setItem("admin-show-vendor-details", this.showVendorDetails);
    },

    /**
     * Toogles AdminExportCartProductsComponent visibility
     *
     * @returns {void}
     */
    toggleExportCartProducts() {
      this.exportCartProductsVisible = !this.exportCartProductsVisible;
    },

    /**
     * Toogles AdminExportPresentationComponent visibility
     *
     * @returns {void}
     */
    toggleExportPresentation() {
      this.exportPresentationVisible = !this.exportPresentationVisible;
    },
  },
};
