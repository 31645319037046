/**
 * ```
 * @prettier
 * ```
 */

/**
 * This file, related to the nuxt store, in responsible for
 * the storage of available modules. As this is used in multiple files,
 * it is pointless and wasteful potentially doing dozens of calls
 * when one can be done and it is then stored in here.
 */
const state = {
  isBulk: 0,
  isCatalogueMode: 0,
  windowWidth: 0,
  activeCategoryId: 0,
  activeCategoryIndex: 0,
  activeCategory: false,
  activeCategoryLoaded: false,
  isCartOpen: false,
  isCategoriesOpen: false,
};

const getters = {
  /**
   * It gets isBulk
   *
   * @param {object} state state
   * @returns {boolean} IsBulk state
   */
  getIsBulk(state) {
    return state.isBulk;
  },

  /**
   * It gets isCatalogueMode
   *
   * @param {object} state state
   * @returns {boolean} IsCatalogueMode state
   */
  getIsCatalogueMode(state) {
    return state.isCatalogueMode;
  },

  /**
   * Get active category index
   *
   * @param {object} state state
   * @returns {boolean} activeCategoryId state
   */
  getActiveCategoryId(state) {
    return state.activeCategoryId;
  },

  /**
   * Get active category loaded
   *
   * @param {object} state state
   * @returns {boolean} activeCategoryLoaded state
   */
  getActiveCategoryLoaded(state) {
    return state.activeCategoryLoaded;
  },

  /**
   * Get active category index
   *
   * @param {object} state state
   * @returns {boolean} activeCategoryIndex state
   */
  getActiveCategoryIndex(state) {
    return state.activeCategoryIndex;
  },

  /**
   * Get active category status
   *
   * @param {object} state state
   * @returns {boolean} activeCategory state
   */
  getActiveCategory(state) {
    return state.activeCategory;
  },
  /**
   * It gets windowWidth
   *
   * @param {object} state state
   * @returns {number} windowWidth state
   */
  getWindowWidth(state) {
    return state.windowWidth;
  },

  /**
   * It gets isCartOpen
   *
   * @param {object} state state
   * @returns {boolean} isCartOpen state
   */
  getIsCartOpen(state) {
    return state.isCartOpen;
  },

  /**
   * It gets isCategoriesOpen
   *
   * @param {object} state state
   * @returns {boolean} isCategoriesOpen state
   */
  getIsCategoriesOpen(state) {
    return state.isCategoriesOpen;
  },
};

const mutations = {
  /**
   * Set loading variable
   *
   * @param {object} state state
   * @param {boolean} isBulk IsBulk value
   * @returns {void}
   */
  setIsBulk(state, isBulk) {
    state.isBulk = isBulk;
  },

  /**
   * Set isCatalogueMode variable
   *
   * @param {object} state state
   * @param {boolean} isCatalogueMode isCatalogueMode value
   * @returns {void}
   */
  setIsCatalogueMode(state, isCatalogueMode) {
    state.isCatalogueMode = isCatalogueMode;
  },

  /**
   * Set activeCategoryId variable
   *
   * @param {object} state state
   * @param {boolean} activeCategoryId activeCategoryId value
   * @returns {void}
   */
  setActiveCategoryId(state, activeCategoryId) {
    state.activeCategoryId = activeCategoryId;
  },

  /**
   * Set activeCategoryLoaded variable
   *
   * @param {object} state state
   * @param {boolean} activeCategoryLoaded activeCategoryLoaded value
   * @returns {void}
   */
  setActiveCategoryLoaded(state, activeCategoryLoaded) {
    state.activeCategoryLoaded = activeCategoryLoaded;
  },

  /**
   * Set activeCategoryIndex variable
   *
   * @param {object} state state
   * @param {boolean} activeCategoryIndex activeCategoryIndex value
   * @returns {void}
   */
  setActiveCategoryIndex(state, activeCategoryIndex) {
    state.activeCategoryIndex = activeCategoryIndex;
  },

  /**
   * Set activeCategory variable
   *
   * @param {object} state state
   * @param {boolean} activeCategory activeCategory value
   * @returns {void}
   */
  setActiveCategory(state, activeCategory) {
    state.activeCategory = activeCategory;
  },

  /**
   * Set windowWidth variable
   *
   * @param {object} state state
   * @param {number} windowWidth windowWidth value
   * @returns {void}
   */
  setWindowWidth(state, windowWidth) {
    state.windowWidth = windowWidth;
  },

  /**
   * Set isCartOpen
   *
   * @param {object} state state
   * @param {boolean} isCartOpen isCartOpen value
   * @returns {void}
   */
  setIsCartOpen(state, isCartOpen) {
    state.isCartOpen = isCartOpen;
  },

  /**
   * Set isCategoriesOpen
   *
   * @param {object} state state
   * @param {boolean} isCategoriesOpen isCategoriesOpen value
   * @returns {void}
   */
  setIsCategoriesOpen(state, isCategoriesOpen) {
    state.isCategoriesOpen = isCategoriesOpen;
  },
};

const actions = {
  /**
   * It changes isBulk state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global isBulk new status
   */
  changeIsBulkState(context, payload) {
    context.commit("setIsBulk", payload);
  },

  /**
   * It changes isCatalogueMode state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global isCatalogueMode new status
   */
  changeIsCatalogueModeState(context, payload) {
    context.commit("setIsCatalogueMode", payload);
  },

  /**
   * It changes activeCategoryId state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global activeCategoryId new status
   */
  changeActiveCategoryIdState(context, payload) {
    context.commit("setActiveCategoryId", payload);
  },

  /**
   * It changes activeCategoryLoaded state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global activeCategoryLoaded new status
   */
  changeActiveCategoryLoadedState(context, payload) {
    context.commit("setActiveCategoryLoaded", payload);
  },

  /**
   * It changes activeCategoryIndex state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global activeCategoryIndex new status
   */
  changeActiveCategoryIndexState(context, payload) {
    context.commit("setActiveCategoryIndex", payload);
  },

  /**
   * It changes activeCategory state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global activeCategory new status
   */
  changeActiveCategoryState(context, payload) {
    context.commit("setActiveCategory", payload);
  },

  /**
   * It changes windowWidth state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global windowWidth new status
   */
  changeWindowWidth(context, payload) {
    context.commit("setWindowWidth", payload);
  },

  /**
   * It changes isCartOpen state
   *
   * @param {object} context store context
   * @param {boolean} payload isCartOpen value
   */
  changeIsCartOpen(context, payload) {
    context.commit("setIsCartOpen", payload);
  },

  /**
   * It changes isCategoriesOpen state
   *
   * @param {object} context store context
   * @param {boolean} payload isCategoriesOpen value
   */
  changeIsCategoriesOpen(context, payload) {
    context.commit("setIsCategoriesOpen", payload);
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
