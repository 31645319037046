/**
 * ```
 * @prettier
 * ```
 */

// Components
import BasePasswordStrength from "@/components/base-password-strength/BasePasswordStrengthComponent.vue";

// Services
import UserService from "@/api/services/resources/user";

// Models
import User from "@/api/models/user";

// Mixins
import CartMixin from "@/mixins/cartMixin.js";
import UserMixin from "@/mixins/userMixin.js";

export default {
  name: "AuthModalSignUp",
  mixins: [CartMixin, UserMixin],
  components: {
    BasePasswordStrength,
  },
  data() {
    const passwordsMatch = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("Passwords do not match"));
        return;
      }
      callback();
    };
    const passwordStrength = (rule, value, callback) => {
      if (!this.passwordIsStrong) {
        callback(new Error("Password is not strong enough"));
        return;
      }
      callback();
    };
    return {
      loading: {
        signup: false,
      },
      error: "",
      form: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        password: "",
        confirmPassword: "",
        terms: false,
      },
      passwordIsStrong: false,
      formRules: {
        firstName: [
          {
            required: true,
            message: "Please insert your first name",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please insert your last name",
          },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Please insert a valid email address",
          },
        ],
        company: [
          {
            required: true,
            message: "Please insert your company name",
          },
        ],
        password: [
          {
            required: true,
            message: "Please insert a password",
          },
          { validator: passwordStrength, trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please confirm your password",
          },
          { validator: passwordsMatch, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /**
     * Handles the signupForm submit event
     *
     * @returns {void}
     */
    onSubmit() {
      this.$refs.signupForm.validate((valid) => {
        if (valid && this.form.terms && this.passwordIsStrong) {
          this.signUp();
        }
      });
    },

    /**
     * Registers the user, creates a site
     * Exports cart to newly registered users
     * cart. Redirects user to checkout
     *
     * @returns {void}
     */
    async signUp() {
      this.loading.signup = true;
      this.error = "";
      let token = null;

      await UserService.register(this.form)
        .then((response) => {
          token = response.token;
        })
        .catch((error) => {
          this.error = error;
        });
      if (!token) {
        this.loading.signup = false;
        return;
      }

      // switch guest token with new user token
      this.$store.dispatch("user/changeToken", token);
      localStorage.setItem("token-override", token);

      // change guest mode
      this.$store.dispatch("user/changeIsGuest", false);

      /**
       * Set user data
       *
       * We cant use getUserDetails() as the portal hasn't being created yet
       */
      const user = await UserService.me().then((user) => {
        return user;
      });
      this.$store.dispatch(
        "user/changeUser",
        new User(
          user.id,
          this.form.email,
          this.form.firstName,
          this.form.lastName
        )
      );

      this.setUserBillingAddress();
      this.activateLogRocket();

      // remove guest portal id
      this.$store.dispatch("user/changePortalId", 0);

      // Gets cart
      this.exportGuestCart();

      this.$router.push("/checkout");
      this.loading.signup = false;
    },

    /**
     * Sets the user billing address
     *
     * @returns {void}
     */
    setUserBillingAddress() {
      const address = {
        email: this.form.email,
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        company: this.form.company,
      };
      this.$store.dispatch("user/changeBillingAddress", address);
    },
  },
};
