/**
 * ```
 * @prettier
 * ```
 */

/* Components */
import AuthModalSignUp from "@/components/auth-modal-signup/AuthModalSignUpComponent.vue";
import AuthModalLogin from "@/components/auth-modal-login/AuthModalLoginComponent.vue";

export default {
  name: "AuthModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "visible",
    event: "changeVisible",
  },
  components: {
    AuthModalSignUp,
    AuthModalLogin,
  },
  data() {
    return {
      showSignup: true,
    };
  },
  computed: {
    /**
     * Get the title of the modal
     *
     * @returns {string} The title of the modal
     */
    getTitle() {
      return this.showSignup
        ? "Create your Swaghut Account"
        : "Login to SwagHut";
    },
  },
};
