export default {
  name: 'QuantityDropdownComponent',
  props: {
    preDefinedQuantities: {
      type: Array,
      default: () => [
        100,
        200,
        500
      ],
    },
    min: {
      type: Number,
      default: 100,
    },
    defaultQuantity: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      quantity: this.defaultQuantity,
    };
  },
  methods: {
    /**
     * Emits onQuantityChanged event
     * 
     * @returns {void}
     */
    onQuantityChanged() {
      if (this.quantity < this.min) {
        return;
      }
      this.$emit('onQuantityChanged', this.quantity);
    }
  }
};
