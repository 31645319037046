/**
 * ```
 * @prettier
 * ```
 */

import AbstractApi from "../abstract-api";
import axios from "@/plugins/axios.js";
import User from "../../models/user";

const abstractApi = new AbstractApi();
const wpRef = abstractApi.getWpRef();
const hubRef = abstractApi.getHubRef();

const UserService = {
  /**
   * Retrieve current logged in user
   *
   * @returns {User} logged in user details
   */
  me() {
    const apiUrl = `${wpRef}wp/v2/users/me`;
    return axios.get(apiUrl).then((response) => {
      const user = Object.assign(new User(), response.data);
      return user;
    });
  },

  /**
   * Retrive user token via email and password
   *
   * @param {object} queryParams Params with users data
   * @returns {Promise} Logged in user or error response
   */
  login(queryParams = {}) {
    const apiUrl = `${hubRef}swaghut/hub/v1/login`;
    return axios
      .post(apiUrl, queryParams)
      .then((response) => {
        if (response.data.statusCode !== 200) {
          throw new Error(response.data.message);
        }
        const data = response.data.data;
        return {
          user: new User(
            data.user.id,
            data.user.email,
            data.user.firstName,
            data.user.lastName,
            data.sites
          ),
          token: data.token,
        };
      })
      .catch((error) => {
        if (error.response) {
          throw new Error(error.response.data.message);
        }
        throw new Error(error.message);
      });
  },

  /**
   * Register a user
   *
   * @param {object} queryParams Params with users data
   * @returns {Promise} Registered user token and site url
   */
  register(queryParams = {}) {
    const apiUrl = `${hubRef}swaghut/hub/v1/signUpOnPackBuilder`;

    const params = {
      first_name: queryParams.firstName,
      last_name: queryParams.lastName,
      company: queryParams.company,
      email: queryParams.email,
      password: queryParams.password,
      confirm_password: queryParams.confirmPassword,
    };
    return axios
      .post(apiUrl, params)
      .then((response) => {
        const data = response.data;
        return {
          success: data.success,
          code: data.code,
          message: data.message,
          email: data.data.email,
          token: data.data.token,
        };
      })
      .catch((error) => {
        const data = error.response.data;
        if (data.code === "portal_exists" || data.code === "email_exists") {
          throw new Error("user_exists");
        }
        throw new Error(error.response.data.message);
      });
  },

  /**
   * Retrieve current logged in users addresses
   * (billing & shipping)
   *
   * @param {number} portalId - portal to retrive address from
   * @returns {Promise} logged in user billing and shipping address
   */
  address(portalId) {
    const apiUrl = `${wpRef}shop-api/v1/swaghut/users/me`;
    return axios
      .get(apiUrl, {
        params: {
          portal_id: portalId,
        },
      })
      .then((response) => {
        return response.data;
      });
  },

  /**
   * Checks if user has the passed capability
   *
   * @param {string} capability Capability to be checked
   * @returns {Promise} Returns capability check promise
   */
  can(capability) {
    const apiUrl = `${wpRef}swaghut/core/v1/user/me/can`;
    const params = {
      cap: capability,
    };

    return axios.get(apiUrl, { params }).then((response) => {
      return response;
    });
  },
};

export default UserService;
