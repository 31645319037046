// Components
import VariationSize from '@/components/variation-size/VariationSizeComponent.vue';

// Services
import ProductsService from '@/api/services/resources/products.js';

// Utils
import { sortSizes } from '@/utils/sizingHelper.js';

export default {
  name: 'CheckoutSizingModal',
  components: {
    VariationSize,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "show",
    event: "show",
  },
  data() {
    return {
      loading: {
        sizing: true,
        creatingOrder: false,
      },
      sizeVariation: {},
      errorMessage: '',
      quantityValid: false,
    };
  },
  watch: {
    show: {
      handler(newValue) {
        if (newValue === true) {
          this.getAvailableSizing();
        }
      },
    },
  },
  methods: {
    /**
     * Gets the available sizing for the products
     *
     * @returns {void}
     */
    async getAvailableSizing() {
      try {
        this.loading.sizing = true;
        this.errorMessage = '';

        // gets products from the cart that have sizing
        const itemsWithSizing = this.$store.getters[
          'cart/getCart'
        ].items.filter((item) => {
          return item.product.hasSizing;
        });

        // gets sizes of each product
        let sizesArray = [];
        for (const item of itemsWithSizing) {
          let variation = await ProductsService.getVariationById(
            item.product.id
          );
          const response = await ProductsService.productVariable(
            variation.parent.id
          );

          const responseSizes = response.product.attributes.find(
            (attribute) => attribute.slug === 'pa_size'
          ).options;

          sizesArray.push(responseSizes);
        }

        // gets the related sizes of each product
        const commonSizes = sizesArray.reduce((acc, curr) => {
          return acc.filter((size) => curr.some((s) => s.slug === size.slug));
        });
        // remove duplicates
        const uniqueCommonSizes = commonSizes.filter((size, index, self) => {
          return self.findIndex((s) => s.slug === size.slug) === index;
        });

        // sets the attribute values that will be shown to the user
        this.sizeVariation = {
          id: 'pa_size',
          name: 'Size',
          options: sortSizes(uniqueCommonSizes, 'name'),
        };
      } catch (error) {
        this.errorMessage = 'There was an error getting the available sizing. Please contact support.';
      }
      this.loading.sizing = false;
    },

    onSelectedSizesChanged(sizes) {
      this.quantityValid = sizes.isQuantityValid;
      this.$store.dispatch("cart/changeSizingQuantity", sizes.sizesFormatted);
    },
  },
};
