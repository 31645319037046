/**
 * ```
 * @prettier
 * ```
 */

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    VuePhoneNumberInput,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    displayDropdown: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
  },

  data() {
    const validateMobile = (rule, value, callback) => {
      if (!this.phoneIsValid) {
        callback(new Error("Phone Number is not valid"));
        return;
      }
      callback();
    };
    return {
      phoneNumberform: {
        phone: this.defaultValue,
      },
      formattedNumber: "",
      countryCode: "",
      phoneIsValid: false,
      focused: false,
      rules: {
        phone: [
          {
            required: true,
            message: "Phone Number is required",
          },
          { validator: validateMobile },
        ],
      },
      validators: {
        validateMobile,
      },
    };
  },

  watch: {
    /**
     * Only change the country code when the country changes and the phone number is empty
     *
     * @param {string} newValue country value
     * @returns {void}
     */
    country(newValue) {
      if (this.phoneNumberform.phone) {
        return;
      }
      this.countryCode = newValue;
    },
  },

  /**
   * Remove placeholder text from VuePhoneNumberInput
   *
   * @returns {void}
   */
  mounted() {
    document.querySelector(".input-tel__input").placeholder = this.placeholder;
  },

  methods: {
    /**
     * Called when the phone number is updated
     *
     *
     * @param {any} data _
     * @returns {void}
     */
    onUpdate(data) {
      this.phoneIsValid = data.isValid;
      this.formattedNumber = data.formattedNumber;
      if (!this.countryCode) {
        this.countryCode = data.countryCode;
      }
    },
    /**
     * Validates phone number and if valid emit value
     *
     * @returns {void}
     */
    validateNumber() {
      this.$refs.phoneNumberInput
        .validate()
        .then(() => {
          this.$emit("formattedNumber", this.formattedNumber);
        })
        .catch(() => {
          this.$emit("formattedNumber", false);
        });
    },
  },
};
