export default {
  name: 'ProductModalDescriptionComponent',
  props: {
    description: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    /**
     * Returns the product description based on the show more state
     *
     * @returns {string} product description
     */
    formattedDescription() {
      if (this.showMore || this.description.length <= this.maxLength) {
        return this.description;
      }

      return `${this.description.substring(0, this.maxLength)}...`;
    },

    /**
     * Returns the button label
     *
     * @returns {string} - The Button label
     */
    buttonLabel() {
      return this.showMore ? 'Show less' : 'Show more';
    },
  },
  methods: {
    /**
     * Toggles the show more state
     *
     * @returns {void}
     */
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
