/**
 * ```
 * @prettier
 * ```
 */

/**
 * This file, related to the nuxt store, in responsible for
 * the storage of available modules. As this is used in multiple files,
 * it is pointless and wasteful potentially doing dozens of calls
 * when one can be done and it is then stored in here.
 */
const state = {
  user: {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    sites: [],
  },
  isGuest: true,
  token: null,
  billingAddress: {},
  portalId: 0,
};

const getters = {
  /**
   * It gets the user
   *
   * @param {object} state state
   * @returns {boolean} user state
   */
  getUser(state) {
    return state.user;
  },

  /**
   * It gets user token
   *
   * @param {object} state state
   * @returns {boolean} token state
   */
  getToken(state) {
    return state.token;
  },

  /**
   * It gets billing address
   *
   * @param {object} state state
   * @returns {boolean} billingAddress state
   */
  getBillingAddress(state) {
    return state.billingAddress;
  },

  /**
   * It gets portalId
   *
   * @param {object} state state
   * @returns {boolean} portalId state
   */
  getPortalId(state) {
    return state.portalId;
  },

  /**
   * It gets isGuest
   *
   * @param {object} state state
   * @returns {boolean} isGuest state
   */
  getIsGuest(state) {
    return state.isGuest;
  },
};

const mutations = {
  /**
   * Set user variable
   *
   * @param {object} state state
   * @param {object} user user value
   * @returns {void}
   */
  setUser(state, user) {
    state.user = user;
  },

  /**
   * Set token variable
   *
   * @param {object} state state
   * @param {object} token token value
   * @returns {void}
   */
  setToken(state, token) {
    state.token = token;
  },

  /**
   * Set billing address variable
   *
   * @param {object} state state
   * @param {object} billingAddress billing address value
   * @returns {void}
   */
  setBillingAddress(state, billingAddress) {
    state.billingAddress = billingAddress;
  },

  /**
   * Set portalId variable
   *
   * @param {object} state state
   * @param {number} portalId portalId value
   * @returns {void}
   */
  setPortalId(state, portalId) {
    state.portalId = portalId;
  },

  /**
   * Set isGuest variable
   *
   * @param {object} state state
   * @param {number} isGuest isGuest value
   * @returns {void}
   */
  setIsGuest(state, isGuest) {
    state.isGuest = isGuest;
  },
};

const actions = {
  /**
   * It changes user state
   *
   * @param {object} context User store context
   * @param {boolean} payload Users new data
   */
  changeUser(context, payload) {
    context.commit("setUser", payload);
  },

  /**
   * It changes token state
   *
   * @param {object} context User store context
   * @param {boolean} payload User token new data
   */
  changeToken(context, payload) {
    context.commit("setToken", payload);
  },

  /**
   * It changes billing address state
   *
   * @param {object} context User store context
   * @param {boolean} payload User billing address new data
   */
  changeBillingAddress(context, payload) {
    context.commit("setBillingAddress", payload);
  },

  /**
   * It changes portalId state
   *
   * @param {object} context User store context
   * @param {boolean} payload portalId new data
   */
  changePortalId(context, payload) {
    context.commit("setPortalId", payload);
  },

  /**
   * It changes isGuest state
   *
   * @param {object} context User store context
   * @param {boolean} payload isGuest new data
   */
  changeIsGuest(context, payload) {
    context.commit("setIsGuest", payload);
  },
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};
