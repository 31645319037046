export default {
  props: {
    variations: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // The variations that should be excluded from the list
    // pass the id of the variation
    exclude: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  created() {
    this.initialiseSelectedVariations();
  },
  data () {
    return {
      selectedVariations: [],
    };
  },
  methods: {
    /** 
     * It returns the variations that are not excluded
     * 
     * @returns {Array} Variations
     **/
    getFilteredVariations() {
      if (this.exclude.length === 0) {
        return this.variations;
      }

      return this.variations.filter(variation => {
        return !this.exclude.includes(variation.id);
      });
    },

    /**
     * It returns the selected variations
     * 
     * @returns {void} 
     **/
    initialiseSelectedVariations() {      
      this.getFilteredVariations().forEach(variation => {
        const selectedOption = variation.options.find(option => {
          return option.slug === variation.selectedItem[0];
        });

        this.selectedVariations.push({
          id: variation.id,
          option: selectedOption || null,
        });
      });
    },

    /**
     * Update the selected variations
     * 
     * @param {string} slug -> The slug of the variation option
     * @param {object} variation -> The variation object itself
     * @returns {void}
     **/
    onVariationSelected(slug, variation) {
      const option = variation.options.find(option => {
        return option.slug === slug;
      });

      this.selectedVariations.find(selectedVariation => {
        return selectedVariation.id === variation.id;
      }).option = option;

      this.$emit('onVariationSelected', {
        id: variation.id,
        option: option,
      });
    }
  },
};