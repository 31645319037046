/**
 * ```
 * @prettier
 * ```
 */

// Mixins
import CartMixin from "@/mixins/cartMixin.js";
import ProductMixin from "@/mixins/productMixin.js";

// Services
import ProductsService from "@/api/services/resources/products.js";

// Models
import Product from "@/api/models/product.js";

// Components
import BaseImage from "@/components/base-image/BaseImage.vue";

export default {
  name: "CartItemComponent",
  mixins: [CartMixin, ProductMixin],
  components: {
    BaseImage,
  },

  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  /**
   * @inheritdoc
   */
  data() {
    return {
      isDetailDisplayed: false,
      showHideDetailsText: "Show Details",
      Product,
    };
  },
  methods: {
    /**
     * Returns the item quantity text
     *
     * @returns {string} Item quantity text
     */
    getQuantityText() {
      if (this.inBulkMode()) {
        return `Qty: ${this.item.quantity}`;
      }
      return `Qty per pack: ${this.item.quantityPerPack}`;
    },

    /**
     * It sends back the chosen color
     *
     * @param {object} item Item to get the color from
     * @returns {string} Item color
     */
    getColor(item) {
      const color = item.variation.find((varia) => {
        if (varia.attribute === "Available colors") {
          return "varia";
        }
      });

      if (color) {
        return color.value;
      }
    },

    /**
     * Opens product model with cart items
     * selected attributes
     *
     * @param {object} item - cart item
     * @returns {void}
     */
    async editCartItem(item) {
      try {
        this.$store.dispatch("product/changeLoadingState", true);
        this.$store.dispatch("product/changeIsEditingState", true);

        let productId = item.product.id;
        let productParentId = item.product.id;
        if (item.product.parent) {
          productParentId = item.product.parent.id;
        }
        const product = Object.assign(new Product());
        product.setId(productParentId);

        const variation = await ProductsService.productVariable(
          productParentId
        );

        this.$store.dispatch("product/changeSelectedProduct", product);
        this.$store.dispatch(
          "product/changeSelectedProductQuantity",
          item.quantityPerPack
        );

        await this.refreshProduct(product, variation.product, productId);

        product.cKey = item.key;
        product.cSelectedSizeQuantity = item.quantityPerSize;

        // open product modal
        this.$store.commit("product/setShowProductModal", true);
      } catch (error) {
        console.error(error);
        this.$store.dispatch("product/changeIsEditingState", false);
      }
      this.$store.dispatch("product/changeLoadingState", false);
    },

    /**
     * It toggles item collapses
     *
     * @returns {void}
     */
    toggleCollapse() {
      this.isDetailDisplayed = !this.isDetailDisplayed;

      if (this.isDetailDisplayed) {
        this.showHideDetailsText = "Hide Details";
        return;
      }
      this.showHideDetailsText = "Show Details";
    },

    /**
     * Checks if the item has sizing
     *
     * @returns {boolean} Returns true if the item has sizing
     */
    itemHasSizing() {
      const sizes = this.item.quantityPerSize;

      return (
        sizes && typeof sizes === "object" && Object.keys(sizes).length > 0
      );
    },
  },
};
