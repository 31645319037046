/**
 * ```
 * @prettier
 * ```
 *
 * Uses Prime Vue - https://www.primefaces.org/primevue-v2
 * Icons - https://www.primefaces.org/primevue-v2/#/icons
 */

/* Components */
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import Checkbox from "primevue/checkbox";
import Message from "primevue/message";
import InlineMessage from "primevue/inlinemessage";
import Divider from "primevue/divider";

/* Styles */
import "primevue/resources/themes/bootstrap4-dark-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

/* Plugins */
import { jsPDF } from "jspdf";

/* Font */
import { RegularFont, BoldFont } from "@/assets/fonts/montserrat-base-64";

/**
 * Services
 */
import ProductService from "@/api/services/resources/products.js";

export default {
  components: {
    Button,
    Sidebar,
    Checkbox,
    Message,
    InlineMessage,
    Divider,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
        export: false,
      },
      pdfName: "presentation",
      sidebarVisible: false,
      exportProgress: 0,
      error: "",
      checkboxFields: [
        {
          label: "Product Name",
          value: "name",
        },
        {
          label: "Product Image",
          value: "image",
        },
        {
          label: "Product Quantity",
          value: "quantity",
        },
        {
          label: "Product Unit Price",
          value: "price",
        },
        {
          label: "Product Code",
          value: "sku",
        },
        {
          label: "Imprint Area",
          value: "imprint",
        },
        {
          label: "Variation",
          value: "variation",
        },
        {
          label: "Product Dimensions",
          value: "dimensions",
        },
      ],
      selectedFields: [],
      selectedAll: false,
    };
  },
  watch: {
    /**
     * Listen to open prop changes and set the sidebar visible accordingly.
     *
     * @param {boolean} newValue - The new value of the open prop.
     */
    open(newValue) {
      this.sidebarVisible = newValue;
    },

    /**
     * Listen to the selected fields and set the selectedAll checkbox accordingly.
     * If all the fields are selected, then set the selectedAll checkbox to true.
     *
     * @param {boolean} fields - The selected fields.
     * @returns {void}
     */
    selectedFields(fields) {
      if (fields.length === this.checkboxFields.length) {
        this.selectedAll = true;
        return;
      }
      this.selectedAll = false;
    },
  },
  computed: {
    /**
     * It computes cart items
     *
     * @returns {Array} Array of items from the cart
     */
    cartItems() {
      return this.$store.getters["cart/getCart"].items || [];
    },
  },
  methods: {
    /**
     * Emitted when the sidebar is toggled.
     *
     * @returns {void}
     */
    onSidebarClosed() {
      this.error = "";
      this.$emit("onClosed", false);
    },

    /**
     * Selects all the fields or deselects all the fields.
     *
     * @returns {void}
     */
    toggleAllFields() {
      if (!this.selectedAll) {
        this.selectedFields = [];
        return;
      }
      this.selectedFields = this.checkboxFields.map((field) => field.value);
    },

    /**
     * Exports selected products to a PDF file
     *
     * @param {Array<{label: string, value: string}>} fields selected fields to export
     * @returns {void}
     */
    async exportPDF(fields) {
      if (fields.length < 1) {
        this.error = "Please select at least one field";
        return;
      }
      this.error = "";
      this.exportProgress = 0;
      this.loading.export = true;

      // we wait for the spinner to render before generating the pdf
      setTimeout(async () => {
        const pdf = await this.generatePDF();
        if (!pdf) {
          this.loading.export = false;
          return;
        }
        pdf.save(`${this.pdfName}.pdf`);
        this.loading.export = false;
      }, 300);
    },

    /**
     * Generates the PDF file
     *
     * @returns {jsPDF} PDF file
     */
    async generatePDF() {
      try {
        let doc = new jsPDF({
          unit: "mm",
          format: "legal",
          orientation: "landscape",
          compress: true,
          userUnit: 150,
        });

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        // Cover
        doc.addImage(
          require("@/assets/presentation/cover.png"),
          "PNG",
          0,
          0,
          width,
          height,
          "",
          "FAST"
        );

        // Add custom font
        doc.addFileToVFS("Montserrat-Regular.ttf", RegularFont);
        doc.addFileToVFS("Montserrat-Bold.ttf", BoldFont);
        doc.addFont("Montserrat-Regular.ttf", "MontserratRegular", "normal");
        doc.addFont("Montserrat-Bold.ttf", "MontserratBold", "bold");

        // Inject Product Pages
        const selectedFields = this.selectedFields;
        const that = this;
        for (const item of this.cartItems) {
          doc.addPage();
          // overlay
          doc.addImage(
            require("@/assets/presentation/overlay.png"),
            "PNG",
            0,
            0,
            width,
            height,
            "",
            "FAST"
          );
          // Position text left
          let textMarginLeft = 170;

          // Product Name & Image
          if (selectedFields.includes("name")) {
            doc.setFontSize(18);
            doc.setFont("MontserratBold", "normal", 700);
            doc.setTextColor(61, 17, 82);
            doc.text(textMarginLeft, 55, item.product.name, { maxWidth: 150 });
          }
          if (selectedFields.includes("image")) {
            const imgProps = doc.getImageProperties(item.product.image);
            const imageDimensions = that.getImageDimensions(
              140,
              140,
              imgProps.width,
              imgProps.height
            );

            doc.addImage(
              item.product.image,
              "JPEG",
              20 + 140 / 2 - imageDimensions.width / 2, // center the image horizontally
              20 + 140 / 2 - imageDimensions.height / 2, // center the image vertically
              imageDimensions.width,
              imageDimensions.height,
              "",
              "FAST"
            );
          }

          // Product Price & Quantity & SKU & imprint
          doc.setFontSize(14);
          doc.setFont("MontserratRegular", "normal", 400);
          doc.setTextColor(0, 0, 0);
          let y = 75;
          if (selectedFields.includes("quantity")) {
            doc.text(textMarginLeft, y, `Quantity: ${item.quantity}`);
            y += 7;
          }
          if (selectedFields.includes("price")) {
            doc.text(
              textMarginLeft,
              y,
              `Unit Price: ${this.$options.filters.currency(item.price)}`
            );
            y += 10;
          }
          doc.setFontSize(12);
          if (selectedFields.includes("sku")) {
            doc.text(textMarginLeft, y, `• Product Code: ${item.product.sku}`);
            y += 7;
          }
          if (selectedFields.includes("imprint")) {
            doc.text(
              textMarginLeft,
              y,
              `• Imprint Area: ${item.product.imprintArea}`
            );
            y += 7;
          }

          // Product Variations
          if (selectedFields.includes("variation")) {
            for (const variation of item.variation) {
              if (variation.slug === "pa_available-colors") {
                doc.text(
                  textMarginLeft,
                  y,
                  `• Selected colour: ${variation.options[0].name}`
                );
                y += 7;
                continue;
              }
              doc.text(
                textMarginLeft,
                y,
                `• ${variation.name}: ${variation.options[0].name}`
              );
              y += 7;
            }
          }

          // Product Dimensions
          if (selectedFields.includes("dimensions")) {
            const product = await ProductService.show(item.product.id);

            const dimensions = product.dimensions;
            for (const [key, val] of Object.entries(dimensions)) {
              doc.text(
                textMarginLeft,
                y,
                `• ${key.charAt(0).toUpperCase() + key.slice(1)}: ${val}` // e.g. Height: 10
              );
              y += 7;
            }
          }

          // Disclaimer
          doc.setFontSize(9);
          doc.text(
            textMarginLeft,
            170,
            `All prices quoted are subject to supplier changes.`
          );
          doc.text(textMarginLeft, 175, `Products are subject to availabilty.`);
          doc.text(
            textMarginLeft,
            180,
            `All Prices are exclusive of VAT, storage and shipping.`
          );
        }

        // Partner
        doc.addPage();
        doc.addImage(
          require("@/assets/presentation/partner.png"),
          "PNG",
          0,
          0,
          width,
          height,
          "",
          "FAST"
        );
        // Team
        doc.addPage();
        doc.addImage(
          require("@/assets/presentation/team.png"),
          "PNG",
          0,
          0,
          width,
          height,
          "",
          "FAST"
        );
        // Back
        doc.addPage();
        doc.addImage(
          require("@/assets/presentation/back.png"),
          "PNG",
          0,
          0,
          width,
          height,
          "",
          "FAST"
        );

        return doc;
      } catch (error) {
        console.error(error);
        this.error =
          "An error occurred building the presentation. Please try again in a minute.";
      }
      return null;
    },

    /**
     * Returns the image height & width to fit a certain size
     * based on the ratio.
     *
     * @param {number} maxWidth - max width you want the image to be
     * @param {number} maxHeight - max hight you want the image to be
     * @param {number} imgWidth - current image width
     * @param {number} imgHeight - current imgae height
     * @returns { { width: number, height: number } } - new image width & height
     */
    getImageDimensions(maxWidth, maxHeight, imgWidth, imgHeight) {
      const ratio = imgHeight / imgWidth;

      if (imgHeight > maxHeight || imgWidth > maxWidth) {
        if (imgHeight > imgWidth) {
          imgHeight = maxHeight;
          imgWidth = imgHeight * (1 / ratio);
        } else if (imgWidth > imgHeight) {
          imgWidth = maxWidth;
          imgHeight = imgWidth * ratio;
        } else {
          imgHeight = maxHeight;
          imgWidth = maxWidth;
        }
      }

      return {
        width: imgWidth,
        height: imgHeight,
      };
    },
  },
};
