/**
 * ```
 * @prettier
 * ```
 */

/**
 * Checkout Request Information Component
 *
 * Props:
 *   orderFields - {object} Order fields to be submitted
 *   orderNoteFields - {object} Order note fields to be submitted
 */
export default {
  props: {
    orderFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    orderNoteFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      uploadStatus: "",
      rules: {
        file: [
          {
            required: true,
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Checks if string is empty
     *
     * @param {string} str string to check
     * @returns {boolean} returns true if empty
     */
    isEmpty(str) {
      if (!str) {
        return true;
      }
      return false;
    },

    /**
     * Called when a logo is uploaded/changed
     *
     * @param {object} file logo
     * @returns {void}
     */
    logoUploaded(file) {
      if (!file) {
        this.uploadStatus = "failed";
        return;
      }
      this.orderFields.file = file;
      this.uploadStatus = "successful";
    },

    /**
     * Dsiables all dates less then today
     *
     * @param {any} current date
     * @returns {boolean} _
     */
    disabledDate(current) {
      return current < this.$moment().subtract(1, "day");
    },

    /**
     * Validates form, if valid then emit continue event
     *
     * @returns {void}
     */
    validateForm() {
      if (this.uploadStatus !== "successful") {
        this.uploadStatus = "failed";
        return;
      }
      this.$emit("continue");
    },
  },
};
