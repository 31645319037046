/**
 * ```
 * @prettier
 * ```
 */

export default class Category {
  constructor() {
    this._id = null;
    this._name = "";
    this._image = "";
    this._slug = "";
    this._parentId = null;
    this._description = "";
    this._subCategories = [];
    this.loadingFinished = false;
  }

  /**
   * Set the category's id
   *
   * @param {number} id Category id
   * @returns {void}
   */
  set id(id) {
    this._id = id;
  }

  /**
   * gets the category's id
   *
   * @returns {number} Category id
   */
  get id() {
    return this._id;
  }

  /**
   * Set the category's name
   *
   * @param {string} name Category name
   * @returns {void}
   */
  set name(name) {
    this._name = name;
  }

  /**
   * Gets the category name
   *
   * @returns {string} Category name
   */
  get name() {
    return this._name;
  }

  /**
   * Sets the category's image
   *
   * @param {object} image image
   * @returns {void}
   */
  set image(image) {
    this._image = image != null && Object.hasOwn(image, "src") ? image.src : "";
  }

  /**
   * Gets the category's image
   *
   * @returns {string} Category image
   */
  get image() {
    return this._image;
  }

  /**
   * Set the category's slug
   *
   * @param {string} slug Category slug
   * @returns {void}
   */
  set slug(slug) {
    this._slug = slug;
  }

  /**
   * Gets the category's slug
   *
   * @returns {string} Category slug
   */
  get slug() {
    return this._slug;
  }

  /**
   * Set the category's parent Id
   *
   * @param {number} parentId Category parent Id
   * @returns {void}
   */
  set parentId(parentId) {
    this._parentId = parentId;
  }

  /**
   * Gets the category's parent Id
   *
   * @returns {number} Category parent Id
   */
  get parentId() {
    return this._parentId;
  }

  /**
   * Set the category's description
   *
   * @param {string} description Category description
   * @returns {void}
   */
  set description(description) {
    this._description = description;
  }

  /**
   * Gets the category's description
   *
   * @returns {string} Category description
   */
  get description() {
    return this._description;
  }

  /**
   * Sets the category's subCategories
   *
   * @param {object} subCategories Attachment
   * @returns {void}
   */
  set subCategories(subCategories) {
    this._subCategories = subCategories.map((child) => {
      const category = new Category();
      category.id = child.id;
      category.name = child.name;
      category.image = child.image;
      category.slug = child.slug;
      category.description = child.description;
      category.parentId = child.parent.id;
      category.subCategories = child.children;
      return category;
    });
  }

  /**
   * Gets the category's subCategories
   *
   * @returns {object} Category subCategories
   */
  get subCategories() {
    return this._subCategories;
  }
}
