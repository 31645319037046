/**
 * ```
 * @prettier
 * ```
 */

/**
 * This file, related to the nuxt store, in responsible for
 * the storage of available modules. As this is used in multiple files,
 * it is pointless and wasteful potentially doing dozens of calls
 * when one can be done and it is then stored in here.
 */
const state = {
  canAccessAdminMenu: false,
  adminMenuPosition: "top",
  showVendorDetails: false,
};

const getters = {
  /**
   * It gets isBulk
   *
   * @param {object} state state
   * @returns {boolean} showVendorDetails state
   */
  getShowVendorDetails(state) {
    return state.showVendorDetails;
  },

  /**
   * It gets canAccessAdminMenu
   *
   * @param {object} state state
   * @returns {boolean} canAccessAdminMenu state
   */
  getCanAccessAdminMenu(state) {
    return state.canAccessAdminMenu;
  },

  /**
   * It gets adminMenuPosition
   *
   * @param {object} state state
   * @returns {boolean} adminMenuPosition state
   */
  getAdminMenuPosition(state) {
    return state.adminMenuPosition;
  },
};

const mutations = {
  /**
   * Set showVendorDetails variable
   *
   * @param {object} state state
   * @param {boolean} showVendorDetails showVendorDetails value
   * @returns {void}
   */
  setShowVendorDetails(state, showVendorDetails) {
    state.showVendorDetails = showVendorDetails;
  },

  /**
   * Set canAccessAdminMenu variable
   *
   * @param {object} state state
   * @param {boolean} canAccessAdminMenu canAccessAdminMenu value
   * @returns {void}
   */
  setCanAccessAdminMenu(state, canAccessAdminMenu) {
    state.canAccessAdminMenu = canAccessAdminMenu;
  },

  /**
   * Set adminMenuPosition variable
   *
   * @param {object} state state
   * @param {boolean} adminMenuPosition adminMenuPosition value
   * @returns {void}
   */
  setAdminMenuPosition(state, adminMenuPosition) {
    state.adminMenuPosition = adminMenuPosition;
  },
};

const actions = {
  /**
   * It changes setShowVendorDetails state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global setShowVendorDetails new status
   */
  changeShowVendorDetailsState(context, payload) {
    context.commit("setShowVendorDetails", payload);
  },

  /**
   * It changes CanAccessAdminMenu state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global CanAccessAdminMenu new status
   */
  changeCanAccessAdminMenu(context, payload) {
    context.commit("setCanAccessAdminMenu", payload);
  },

  /**
   * It changes adminMenuPosition state
   *
   * @param {object} context Global store context
   * @param {boolean} payload Global adminMenuPosition new status
   */
  changeAdminMenuPosition(context, payload) {
    context.commit("setAdminMenuPosition", payload);
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
