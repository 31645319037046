/**
 * ```
 * @prettier
 * ```
 */

export default class ProductVendor {
  constructor(
    id = null,
    parentId = null,
    productName = "",
    productCode = "",
    productLink = "",
    productSampleStock = "",
    productHsCode = "",
    productCountryOfOrigin = "",
    vendorName = "",
    vendorEmail = "",
    vendorPhone = "",
    swaghutProductSKU = "",
    swaghutProductName = "",
    swaghutProductAttributes = {},
    pricingTable = [],
    hasPricingTable = false,
    fallbackPrice = 0
  ) {
    this.id = id;
    this.parentId = parentId;
    this.productName = productName;
    this.productCode = productCode;
    this.productLink = productLink;
    this.productSampleStock = productSampleStock;
    this.productHsCode = productHsCode;
    this.productCountryOfOrigin = productCountryOfOrigin;
    this.vendorName = vendorName;
    this.vendorEmail = vendorEmail;
    this.vendorPhone = vendorPhone;
    this.swaghutProductSKU = swaghutProductSKU;
    this.swaghutProductName = swaghutProductName;
    this.swaghutProductAttributes = swaghutProductAttributes;
    this.pricingTable = pricingTable;
    this.hasPricingTable = hasPricingTable;
    this.fallbackPrice = fallbackPrice;
  }

  /**
   * Gets a specific attribute based on attribute name
   *
   * @param {string} attribute -> attribute name
   * @returns {Array} attribute values
   */
  getProductAttribute(attribute) {
    if (!this.swaghutProductAttributes[attribute]) {
      return [];
    }
    return this.swaghutProductAttributes[attribute];
  }
}
