/**
 * ```
 * @prettier
 * ```
 */

/**
 * Components
 */
import ProductModal from "@/components/product-modal/ProductModalComponent.vue";
import ProductsCategory from "@/components/products-category/ProductsCategoryComponent.vue";

/**
 * Mixins
 */
import ProductMixin from "@/mixins/productMixin.js";
import CartMixin from "@/mixins/cartMixin.js";

/**
 * Product List Component
 *
 * Props:
 *   categories - {array} categories for the products list
 */
export default {
  components: {
    ProductsCategory,
    ProductModal,
  },

  mixins: [ProductMixin, CartMixin],

  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  /**
   * @inheritdoc
   */
  data() {
    return {
      categoriesCount: 0,
      enqueuedCategories: [],
      activeCategory: {},
      observer: null,
      categoryDelay: [],
      delayApplied: false,

      openProductModal: false,
    };
  },
  watch: {
    categories() {},
  },
  methods: {
    /**
     * Closes the product modal
     *
     * @returns {void}
     */
    onProductModalClose() {
      this.$store.commit("product/setShowProductModal", false);
    },

    /**
     * Sleep
     *
     * @param {number} milliseconds milliseconds
     * @returns {void} sleep
     */
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },

    /**
     * Return Product based on User's Organization
     *
     * @param {object} product Product object
     * @returns {string} Product's price
     */
    getProductPrice(product) {
      return String(product.price);
    },
  },
};
