/**
 * ```
 * @prettier
 * ```
 */

import LogRocket from "logrocket";

/**
 * Models
 */
import User from "@/api/models/user";

/**
 * Services
 */
import UserService from "@/api/services/resources/user";

const userMixin = {
  methods: {
    /**
     * Gets the users account details
     *
     * @returns {object} {
     *  user,
     *  billing
     * }
     */
    async getUserDetails() {
      const portalId = this.$store.getters["user/getPortalId"];
      const response = await UserService.address(portalId);

      const user = new User(
        response.id,
        response.email,
        response.billing.first_name,
        response.billing.last_name
      );

      this.$store.dispatch("user/changeUser", user);
      this.$store.dispatch("user/changeBillingAddress", response.billing);
      this.activateLogRocket();

      return {
        user,
        billing: response.billing,
      };
    },

    /**
     * Activates log rocket using logged in users data
     *
     * @returns {void}
     */
    activateLogRocket() {
      const user = this.$store.getters["user/getUser"];
      LogRocket.identify(String(user.id), {
        name: user.fullName,
        email: user.email,
      });
    },
  },
};

export default userMixin;
