/**
 * ```
 * @prettier
 * ```
 */

import Vue from "vue";
import Vuex from "vuex";

/**
 * Modules
 */
import cart from "./modules/cart";
import global from "./modules/global";
import product from "./modules/product";
import user from "./modules/user";
import admin from "./modules/admin";

import _ from "lodash";

const modules = {
  cart,
  global,
  product,
  user,
  admin,
};

Vue.use(Vuex);

export default new Vuex.Store({
  mutations: {
    resetState(state) {
      _.forOwn(modules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
      });
    },
  },
  modules,
});
