/**
 * ```
 * @prettier
 * ```
 */

import Product from "./product.js";
import ProductAttribute from "./product-attribute.js";

export default class ProductVariation extends Product {
  /**
   * Returns true if the productAttributes param matches with
   * the current product attributes by name and option value
   *
   * @param {Array<ProductAttribute>} productAttributes List of products attributes.
   * @returns {boolean} True if the attributes match.
   */
  matchVariation(productAttributes) {
    if (!this.attributes || !productAttributes) {
      return false;
    }

    for (const productAttribute of productAttributes) {
      let found = this.attributes.find((attribute) => {
        return (
          attribute.name === productAttribute.name &&
          attribute.option === productAttribute.option
        );
      });

      if (!found) {
        return false;
      }
    }

    return true;
  }
}
