/**
 * ```
 * @prettier
 * ```
 */

export default {
  props: {
    avatars: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    button: {
      type: Object,
      required: false,
      default: () => {
        return {
          link: "",
          text: "",
        };
      },
    },
  },
};
