/**
 * ```
 * @prettier
 * ```
 */

/**
 * Models
 */
import Product from "@/api/models/product";

/**
 * Services
 */
import OrdersService from "@/api/services/resources/orders.js";
import CartService from "@/api/services/resources/cart.js";
import ProductsService from "@/api/services/resources/products.js";

const orderMixin = {
  methods: {
    /**
     * Create a new order as a draft
     *
     * @param {object} customerDetails 
     * { email: "",
       file: [],
       first_name: "",
       last_name: "",
       phone: "",
       company: "" },
     * @param {object} note note to be included in order
     * @returns {void}
     */
    createOrder(customerDetails = [], note = []) {
      /**
       * Create order using woocomm cart
       */
      OrdersService.storeCart().then((responseStoreCart) => {
        if (responseStoreCart.status === 200) {
          this.getLineItems(responseStoreCart.data.order_id, customerDetails);
          this.createOrderNote(responseStoreCart.data.order_id, note);
        }
      });
    },

    /**
     * Gets the order line items and adds the variations to them
     * to be updated
     *
     * @param {number} orderId the id of the selected order
     * @param {object} customerDetails deatils of customer
     * @returns {void}
     */
    async getLineItems(orderId, customerDetails) {
      if (this.inBulkMode()) {
        let responseOrder = await OrdersService.show(orderId).then(
          (responseOrder) => {
            return responseOrder;
          }
        );
        var lineItems = await this.buildVariationBreakdownForBulk(
          responseOrder.line_items
        );
        var orderMeta = await this.buildOrderMetaForBulk();
        await this.updateOrderAddressStatus(
          orderId,
          lineItems,
          orderMeta,
          customerDetails
        );
      } else {
        OrdersService.show(orderId).then((responseOrder) => {
          const lineItems = this.buildVariationBreakdown(
            responseOrder.line_items
          );
          const orderMeta = this.buildOrderMeta();
          this.updateOrderAddressStatus(
            orderId,
            lineItems,
            orderMeta,
            customerDetails
          );
        });
      }
    },

    /**
     * It builds up items variations breakdown
     *
     * @param {Array} lineItems the lineItems to update
     * @returns {Array} Cart items with variations breakdown
     */
    buildVariationBreakdown(lineItems) {
      const variations = this.$store.getters["cart/getVariations"];

      let updatedlineItems = [];

      lineItems.forEach((item) => {
        variations.forEach((variation) => {
          if (item.variation_id == variation.id) {
            let metaData = [];

            //handle size attribute
            if (variation.attribute === "Size") {
              let metaValue = "";
              variation.options.forEach((option) => {
                if (option.value == null) {
                  option.value = 0;
                }
                metaValue += option.name + ":" + option.value + ",";
              });
              metaData.push({
                key: "apparel_size",
                value: metaValue,
              });
            } else {
              variation.options.forEach((option) => {
                let md = {
                  key: option.name,
                  value: option.value,
                };

                metaData.push(md);
              });
            }

            let toAdd = {
              id: item.id,
              product_id: item.product_id,
              name: item.name,
              meta_data: metaData,
            };

            updatedlineItems.push(toAdd);
          }
        });
      });

      return updatedlineItems;
    },

    /**
     * It builds up items variations breakdown for bulk
     *
     * @param {Array} lineItems the lineItems to update
     * @returns {Array} Cart items with variations breakdown
     */
    async buildVariationBreakdownForBulk(lineItems) {
      const variations = await CartService.getCart().then((response) => {
        return response.items;
      });
      var updatedlineItems = [];

      lineItems.forEach((item) => {
        variations.forEach((variation) => {
          if (item.variation_id == variation.product.id) {
            let metaData = [];

            //handle size attribute
            if (Object.keys(variation.quantityPerSize).length > 0) {
              var metaValue = "";

              for (const sizeKey in variation.quantityPerSize) {
                if (variation.quantityPerSize[sizeKey] == null) {
                  variation.quantityPerSize[sizeKey] = 0;
                }
                metaValue +=
                  sizeKey + ":" + variation.quantityPerSize[sizeKey] + ",";
              }

              metaData.push({
                key: "apparel_size",
                value: metaValue,
              });
            } else {
              variation.variation.forEach((option) => {
                if (option.options.length > 0) {
                  let md = {
                    key: option.name,
                    value: option.options[0].name,
                  };

                  metaData.push(md);
                }
              });
            }

            let toAdd = {
              id: item.id,
              product_id: item.product_id,
              name: item.name,
              meta_data: metaData,
            };
            updatedlineItems.push(toAdd);
          }
        });
      });

      return updatedlineItems;
    },

    /**
     * It builds up the order meta
     *
     * @returns {Array} order meta to be added
     */
    buildOrderMeta() {
      let meta = [];

      const sizing = this.$store.getters["cart/getSizingQuantity"];
      if (!sizing || Object.keys(sizing).length === 0) {
        return meta;
      }

      meta.push({
        key: "apparel_size",
        value: Object.keys(sizing)
          .map((key) => `${key}:${sizing[key]}`)
          .join(","),
      });

      return meta;
    },

    /**
     * It builds up the order meta for bulk
     *
     * @returns {Array} order meta to be added
     */
    async buildOrderMetaForBulk() {
      var meta = [];
      //size meta
      const variations = await CartService.getCart().then((response) => {
        return response.items;
      });

      variations.forEach((variation) => {
        if (Object.keys(variation.quantityPerSize).length > 0) {
          var metaValue = "";
          for (const sizeKey in variation.quantityPerSize) {
            if (variation.quantityPerSize[sizeKey] == null) {
              variation.quantityPerSize[sizeKey] = 0;
            }
            metaValue +=
              sizeKey + ":" + variation.quantityPerSize[sizeKey] + ",";
          }
          meta.push({
            key: "apparel_size",
            value: metaValue,
          });
        }
      });

      if (this.$store.getters["global/getIsBulk"]) {
        meta.push({
          key: "isBulk",
          value: 1,
        });
      }

      const portalId = this.$store.getters["user/getPortalId"];
      if (portalId) {
        meta.push({
          key: "_location_portal_id",
          value: portalId,
        });
      }

      return meta;
    },

    /**
     * It updates order's address and status then
     * uploads files
     *
     * @param {number} orderId Order to be updated
     * @param {Array} lineItems updated lineItems with variations
     * @param {Array} orderMeta order meta to be added
     * @param {object} customerDetails deatils of customer
     * @returns {void}
     */
    async updateOrderAddressStatus(
      orderId,
      lineItems,
      orderMeta,
      customerDetails
    ) {
      if (!customerDetails.state) {
        customerDetails.state = "";
      }
      const catalogueMode = this.$store.getters["global/getIsCatalogueMode"];
      let queryParams = {
        line_items: lineItems,
        status: "on-hold",
        meta_data: orderMeta,
      };

      if (!catalogueMode) {
        queryParams.billing = customerDetails;
        queryParams.shipping = customerDetails;
      }

      OrdersService.update(orderId, queryParams).then((responseUpdate) => {
        if (responseUpdate.status === 200 || responseUpdate.status === 201) {
          if (catalogueMode) {
            CartService.clearCart().then(() => {
              this.$router.push("/checkout");
            });
            this.loading.creatingOrder = false;
            return;
          }
          CartService.getCart().then((response) => {
            const cartItems = response.items;
            const order = responseUpdate.data;

            /**
             * Creates upload promises
             */
            const uploadPromises = cartItems.map((item, index) => {
              return this.uploadArtworkFile(
                customerDetails.file,
                order.line_items[index].id,
                order.id,
                item.product.id
              );
            });

            if (!uploadPromises || uploadPromises.length === 0) {
              this.$emit("isOrderSuccessfull", true);
            }

            Promise.all(uploadPromises).then(() => {
              this.$emit("isOrderSuccessfull", true);
            });

            CartService.clearCart();
          });
        }
      });
    },

    /**
     * Uploads files(artwork) into the Order created previously
     *
     * @param {string} file File to be uploaded
     * @param {number} itemId Item ID
     * @param {number} orderId Order ID
     * @param {number} productId Product ID
     * @returns {void}
     */
    uploadArtworkFile(file, itemId, orderId, productId) {
      this.fileService.getFileName(file).then((fileName) => {
        const bodyFormData = new FormData();
        this.getProduct(productId).then((product) => {
          const uploadFilesOptions = product.custom_options.upload_files;
          if (uploadFilesOptions.rules.length > 0) {
            bodyFormData.append("file", file, fileName);
            bodyFormData.append("item_id", itemId);
            bodyFormData.append("product_id", product.id);
            bodyFormData.append("rule_id", uploadFilesOptions.rules[0].rule_id);
            OrdersService.uploadFile(orderId, bodyFormData);
          }
        });
      });
    },

    /**
     * Gets the product by the id. If the product is a variation get the parent product.
     *
     * @param {number} productId Product ID
     * @returns {Product} The product object
     */
    getProduct(productId) {
      return ProductsService.show(productId).then((product) => {
        if (!product.isVariable) {
          return product;
        }

        return ProductsService.show(product.parent_id);
      });
    },

    /**
     * Creates order note using data from
     * order request form
     *
     * @param {number} orderId order to add note to
     * @param {object} note note to be included in order
     * @returns {void}
     */
    createOrderNote(orderId, note) {
      let noteString = "";
      for (var field in note) {
        let value = note[field];
        if (typeof value === "string" || value instanceof String) {
          value = note[field].trim();
        }
        if (field === "date_needed") {
          value = this.$moment(value).format("YYYY-MM-DD");
        }

        if (value === "") {
          continue;
        }

        noteString += `${this.capitalize(
          field.replace(/_/g, " ")
        )} - ${value}\n`;
      }

      if (noteString === "") {
        return;
      }

      OrdersService.createNote(orderId, { note: noteString });
    },

    /**
     * Capitalizes string
     *
     * @param {string} string string to capitalize
     * @returns {string} capitalized string
     */
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};

export default orderMixin;
