/**
 * ```
 * @prettier
 * ```
 */

/**
 * Services
 */
import CartService from "@/api/services/resources/cart.js";

const cartMixin = {
  data() {
    return {
      packagingError: false,
    };
  },
  computed: {
    /**
     * It returns whether cart is loading or not
     *
     * @returns {boolean} Cart loading state
     */
    isCartLoading() {
      return this.$store.getters["cart/getLoading"];
    },

    /**
     * It computes cart
     *
     * @returns {Array} Array of items from the cart
     */
    cart() {
      return this.$store.getters["cart/getCart"];
    },

    /**
     * It computes pack quantity
     *
     * @returns {number} Returns pack quantity
     */
    packQuantity() {
      return this.$store.getters["cart/getPackQuantity"];
    },
  },
  methods: {
    /**
     * Sets the cart loading state
     *
     * @param {boolean} isLoading -> true to show cart loading state
     * @returns {void}
     */
    setCartLoading(isLoading) {
      this.$store.dispatch("cart/changeLoadingState", isLoading);
    },

    /**
     * Returns if user is a guest
     *
     * @returns {boolean} true if guest
     */
    isGuest() {
      return this.$store.getters["user/getIsGuest"];
    },

    /**
     * Returns true if in bulk mode
     *
     * @returns {boolean} true if inBulk mode
     */
    inBulkMode() {
      return !!this.$store.getters["global/getIsBulk"];
    },

    /**
     * Opens the mobile cart
     *
     * @returns {void}
     */
    openMobileCart() {
      if (this.$store.getters["global/getWindowWidth"] > 1024) {
        return;
      }
      this.$store.dispatch("global/changeIsCartOpen", true);
    },

    /**
     * Readys the cart for use
     *
     * @returns {void}
     */
    async initialiseCart() {
      this.setCartLoading(true);

      // sets the cart mode
      await CartService.setPackMode(!this.inBulkMode());

      // Adds pre built pack to cart if required
      if (
        this.$route.query.isBundle === "1" &&
        this.$route.query.bundledProductId
      ) {
        this.openMobileCart();
        await CartService.addBundleToCart(
          Number(this.$route.query.bundledProductId)
        );
      }

      await this.getCart(false);
      this.setCartLoading(false);
    },

    /**
     * Gets the  total amount of packs
     * in the cart (pack quantity)
     *
     * @returns {Promise<number>} - pack quantity
     */
    async getPackQuantity() {
      const quantity = await CartService.getPackQuantity();
      this.$store.dispatch("cart/changePackQuantity", quantity);
      return quantity;
    },

    /**
     * Gets the  total amount of packs
     * in the cart (pack quantity)
     *
     * @param {number} quantity - pack quantity to be
     * @returns {void}
     */
    async setPackQuantity(quantity) {
      this.setCartLoading(true);

      this.$store.dispatch("cart/changePackQuantity", quantity);
      await CartService.setPackQuantity(quantity);

      await this.getCart(false);
      this.setCartLoading(false);
    },

    /**
     * Gets the user || guest cart
     *
     * @param {boolean} showLoader - hides loader if set to false, true by default
     * @returns {any} - users cart
     */
    async getCart(showLoader = true) {
      // don't change the loading state at all, if false
      if (showLoader) {
        this.setCartLoading(true);
      }

      const cart = await CartService.getCart();
      this.$store.dispatch("cart/setCart", cart);

      if (showLoader) {
        this.setCartLoading(false);
      }

      return cart;
    },

    /**
     * Adds product to the cart
     *
     * @param {product} product - product to add
     * @param {number} quantity - product quantity (per pack)
     * @param {object} sizes - Product sizes
     * @returns {boolean} - true if added to cart
     */
    async addToCart(product, quantity, sizes = {}) {
      // check if we can have more than 1 packaging in the cart
      if (!this.allowMultiplePackaging(product)) {
        this.$notification.error({
          message: "Packaging",
          description:
            "You can only add one packaging item to your cart. Remove the current packaging from your cart and try again.",
        });
        return false;
      }

      try {
        this.setCartLoading(true);
        this.openMobileCart();

        // if product is being edited, update the product instead
        if (product.cKey) {
          await this.updateUserCartItem(product, quantity, sizes);
          return;
        }

        // gets variation values to pass to api
        const variations = this.formatVariationsForApi(
          product.variationsAttributes
        );

        await CartService.addToCart(
          product.parentData.id ? product.parentData.id : product.id,
          quantity,
          variations,
          sizes
        );
        await this.getCart(false);
      } finally {
        this.setCartLoading(false);
      }

      return true;
    },

    /**
     * Updates the user cart item
     *
     * @param {product} product - product to add
     * @param {number} quantity - product quantity (per pack)
     * @param {object} sizes - Product sizes
     */
    async updateUserCartItem(product, quantity, sizes = {}) {
      // gets variation values to pass to api
      const variations = this.formatVariationsForApi(
        product.variationsAttributes
      );

      await CartService.updateCartItem(
        product.cKey,
        product.cId,
        quantity,
        variations,
        sizes
      );
      await this.getCart();
    },

    /**
     * Removes the cart item
     *
     * @param {string} key - the item key (cartItemKey)
     */
    async removeCartItem(key) {
      this.setCartLoading(true);

      await CartService.removeCartItem(key);

      await this.getCart();
      this.setCartLoading(false);
    },

    /**
     * Clears the users cart
     *
     * @returns {void}
     */
    async clearCart() {
      this.setCartLoading(true);
      this.$store.dispatch("cart/cleanUpCart");

      await CartService.clearCart();
      await this.getCart(false);

      this.setCartLoading(false);
    },

    /**
     * Moves the guest cart to a users cart
     *
     * @returns {void}
     */
    async exportGuestCart() {
      this.setCartLoading(true);

      const cart = await CartService.transferCart();
      this.$store.dispatch("cart/setCart", cart);

      this.setCartLoading(false);
    },

    /**
     * formats variation values to pass to api
     *
     * @param {object} variations - the product variations
     * @returns {object} formatted variations
     */
    formatVariationsForApi(variations) {
      let result = {};
      for (let item of variations) {
        let key = item.id;
        if (item.options.length > 0) {
          result[key] = item.selectedItem[0];
          continue;
        }

        result[key] = item.options[0].slug;
      }
      return result;
    },

    /**
     * Checks if multiple packaging in the cart is allowed
     *
     * @param {object} product - the product adding to the cart
     * @returns {boolean} true if multiple packaging is allowed
     */
    allowMultiplePackaging(product) {
      const cartItems = this.$store.getters["cart/getCart"].items || [];
      const hasPackaging = cartItems.some((item) => {
        return item.product.isPackaging;
      });

      return (
        this.inBulkMode() ||
        product.cKey ||
        product.cCategory !== 536 ||
        !hasPackaging
      );
    },
  },
};

export default cartMixin;
