/**
 * ```
 * @prettier
 * ```
 */

// Components
import QuantityDropdown from "@/components/quantity-dropdown/QuantityDropdownComponent.vue";
import BaseDotsLoader from "@/components/base-dots-loader/BaseDotsLoaderComponent.vue";
import AuthModal from "@/components/auth-modal/AuthModalComponent.vue";

// Mixins
import CartMixin from "@/mixins/cartMixin.js";

export default {
  name: "CartFooter",
  mixins: [CartMixin],
  components: {
    QuantityDropdown,
    BaseDotsLoader,
    AuthModal,
  },
  data() {
    return {
      isAuthModalVisible: false,
    };
  },
  computed: {
    /**
     * If the continue button should be shown
     *
     * @returns {boolean} - true or false
     **/
    showContinueButton() {
      return this.$route.name !== "CheckoutPage";
    },
  },
  methods: {
    /**
     * Go to checkout page or show sign-up modal
     *
     * @returns {void}
     */
    goToCheckout() {
      if (this.$store.getters["user/getIsGuest"]) {
        this.isAuthModalVisible = true;
        return;
      }
      this.$router.push("/checkout");
    },
  },
};
