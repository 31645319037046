/**
 * ```
 * @prettier
 * ```
 */

import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

export default class AbstractApi {
  /**
   * Constructor
   */
  constructor() {
    this.wooCommerceApiVersion = "wc/v3";
  }

  /**
   * Create WooCommerce API instance
   *
   * @returns {object} Woocommerce API Instance
   */
  getWoocommerceRef() {
    const wooCommerceApi = new WooCommerceRestApi({
      consumerKey: process.env.VUE_APP_API_SHOPFRONT_CONSUMER_KEY,
      consumerSecret: process.env.VUE_APP_API_SHOPFRONT_CONSUMER_SECRET,
      url: process.env.VUE_APP_API_URL_SHOPFRONT,
      version: this.wooCommerceApiVersion,
    });

    return wooCommerceApi;
  }

  /**
   * Get URL from the config file for WP
   *
   * @returns {string} Wp API URL
   */
  getWpRef() {
    return process.env.VUE_APP_API_URL_SHOPFRONT + "wp-json/";
  }

  /**
   * Get URL from the config file for WP
   *
   * @returns {string} Wp API URL
   */
  getHubRef() {
    return process.env.VUE_APP_API_URL_HUB + "wp-json/";
  }
}
