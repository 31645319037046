/**
 * ```
 * @prettier
 * ```
 */

export default {
  name: "BaseImageComponent",
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: String,
      required: false,
      default: "eager",
    },
    fallback: {
      type: String,
      required: false,
      default: require("@/assets/images/stock-images/placeholder.png"),
    },
  },
};
