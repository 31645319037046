/**
 * ```
 * @prettier
 * ```
 */

/**
 * Mixins
 */
import CartMixin from "@/mixins/cartMixin.js";
import ProductMixin from "@/mixins/productMixin.js";

/**
 * Components
 */
import ProductCardQuickAdd from "@/components/product-card-quick-add/ProductCardQuickAddComponent.vue";
import BaseImage from "@/components/base-image/BaseImage.vue";

/**
 * Product Item Component
 *
 * Props:
 *   product - {object} product object to be displayed
 */
export default {
  mixins: [CartMixin, ProductMixin],
  components: {
    ProductCardQuickAdd,
    BaseImage,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      quickAddLoading: false,
      showQuickAddSuccess: false,
    };
  },
  created() {
    this.product.parentData = Object.assign(this.product);
  },
  computed: {
    /**
     * Returns product image
     *
     * @returns {string} Product image source path
     */
    productImage() {
      return this.product.parentData.image
        ? this.product.parentData.image
        : this.product.image;
    },
  },

  methods: {
    /**
     * Gets the price from the pricing table
     *
     * @returns {number} Product price
     */
    getProductPrice() {
      let quantity = 1;
      if (this.inBulkMode()) {
        quantity = this.product.defaultQuantity;
      }

      return this.getPriceFromTable(
        this.product.parentData.enablePriceTable,
        this.product.parentData.prices,
        quantity,
        this.product.parentData.price
      );
    },

    /**
     * It returns selected product to parent component to display product details
     *
     * @param {object} product - the product to display
     * @returns {void}
     */
    showProduct(product) {
      this.$emit("emit-selected-product", product);
    },
  },
};
