/**
 * ```
 * @prettier
 * ```
 */

/**
 * This file, related to the nuxt store, in responsible for
 * the storage of available modules. As this is used in multiple files,
 * it is pointless and wasteful potentially doing dozens of calls
 * when one can be done and it is then stored in here.
 */
const state = {
  isEditing: false,
  loading: false,
  selectedProduct: null,
  selectedProductQuantity: null,
  productTypes: [],
  allProductsLoaded: false,
  allTotalCategories: 0,
  remainingVisibleCategories: false,
  showProductModal: false,
};

const getters = {
  /**
   * It gets isEditing
   *
   * @param {object} state state
   * @returns {boolean} IsEditing state
   */
  getIsEditing(state) {
    return state.isEditing;
  },

  /**
   * It gets loading
   *
   * @param {object} state state
   * @returns {boolean} Loading state
   */
  getLoading(state) {
    return state.loading;
  },

  /**
   * It gets selected product
   *
   * @param {object} state state
   * @returns {object} SelectedProduct state
   */
  getSelectedProduct(state) {
    return state.selectedProduct;
  },

  /**
   * It gets selected product quantity
   *
   * @param {object} state state
   * @returns {object} selectedProductQuantity state
   */
  getSelectedProductQuantity(state) {
    return state.selectedProductQuantity;
  },

  /**
   * It gets All products loading status
   *
   * @param {object} state state
   * @returns {object} allProductsLoaded state
   */
  getAllProductsLoaded(state) {
    return state.allProductsLoaded;
  },

  /**
   * It gets allTotalCategories status
   *
   * @param {object} state state
   * @returns {object} allTotalCategories state
   */
  getAllTotalCategories(state) {
    return state.allTotalCategories;
  },

  /**
   * It gets ramaining visible categories status
   *
   * @param {object} state state
   * @returns {object} remainingVisibleCategories state
   */
  getRemainingVisibleCategories(state) {
    return state.remainingVisibleCategories;
  },

  /**
   * It gets productTypes
   *
   * @param {object} state state
   * @returns {Array} productType state
   */
  getProductTypes(state) {
    return state.productTypes;
  },

  /**
   * It gets showProductModal
   *
   * @param {object} state state
   * @returns {boolean} showProductModal state
   */
  getShowProductModal(state) {
    return state.showProductModal;
  },
};

const mutations = {
  /**
   * Set isEditing variable
   *
   * @param {object} state state
   * @param {boolean} isEditing IsEditing value
   * @returns {void}
   */
  setIsEditing(state, isEditing) {
    state.isEditing = isEditing;
  },

  /**
   * Set loading variable
   *
   * @param {object} state state
   * @param {boolean} loading Loading value
   * @returns {void}
   */
  setLoading(state, loading) {
    state.loading = loading;
  },

  /**
   * Set selected product variable
   *
   * @param {object} state state
   * @param {object} selectedProduct Selected product value
   * @returns {void}
   */
  setSelectedProduct(state, selectedProduct) {
    state.selectedProduct = selectedProduct;
  },

  /**
   * Set selected product quantity
   *
   * @param {object} state state
   * @param {number} selectedProductQuantity Selected product quantity value
   * @returns {void}
   */
  setSelectedProductQuantity(state, selectedProductQuantity) {
    state.selectedProductQuantity = selectedProductQuantity;
  },

  /**
   * Set all products loading status
   *
   * @param {object} state state
   * @param {number} allProductsLoaded Selected product quantity value
   * @returns {void}
   */
  setAllProductsLoaded(state, allProductsLoaded) {
    state.allProductsLoaded = allProductsLoaded;
  },

  /**
   * Set allTotalCategories status
   *
   * @param {object} state state
   * @param {number} allTotalCategories first category loaded
   * @returns {void}
   */
  setAllTotalCategories(state, allTotalCategories) {
    state.allTotalCategories = allTotalCategories;
  },

  /**
   * Set remainingVisibleCategories status
   *
   * @param {object} state state
   * @param {number} remainingVisibleCategories remaining visible categories
   * @returns {void}
   */
  setRemainingVisibleCategories(state, remainingVisibleCategories) {
    state.remainingVisibleCategories = remainingVisibleCategories;
  },

  /**
   * Set selected productTypes
   *
   * @param {object} state state
   * @param {object} productTypes productTypes value
   * @returns {void}
   */
  setProductTypes(state, productTypes) {
    state.productTypes = productTypes;
  },

  /**
   * Set showProductModal variable
   *
   * @param {object} state state
   * @param {boolean} showProductModal showProductModal value
   * @returns {void}
   */
  setShowProductModal(state, showProductModal) {
    state.showProductModal = showProductModal;
  },
};

const actions = {
  /**
   * It changes isEditing state
   *
   * @param {object} context products store context
   * @param {boolean} payload products isEditing new status
   */
  changeIsEditingState(context, payload) {
    context.commit("setIsEditing", payload);
  },

  /**
   * It changes loading state
   *
   * @param {object} context products store context
   * @param {boolean} payload products loading new status
   */
  changeLoadingState(context, payload) {
    context.commit("setLoading", payload);
  },

  /**
   * It changes selected product state
   *
   * @param {object} context products store context
   * @param {object} payload products selected product new data
   */
  changeSelectedProduct(context, payload) {
    context.commit("setSelectedProduct", payload);
  },

  /**
   * It changes All products loading status
   *
   * @param {object} context products store context
   * @param {object} payload all products loading status
   */
  changeAllProductsLoaded(context, payload) {
    context.commit("setAllProductsLoaded", payload);
  },

  /**
   * It changes allTotalCategories status
   *
   * @param {object} context products store context
   * @param {object} payload allTotalCategories status
   */
  changeAllTotalCategories(context, payload) {
    context.commit("setAllTotalCategories", payload);
  },

  /**
   * It changes changeRemainingVisibleCategories status
   *
   * @param {object} context products store context
   * @param {object} payload changeRemainingVisibleCategories status
   */
  changeRemainingVisibleCategories(context, payload) {
    context.commit("setRemainingVisibleCategories", payload);
  },
  /**
   * It changes selected product quantity
   *
   * @param {object} context products store context
   * @param {number} payload products selected product new quantity
   */
  changeSelectedProductQuantity(context, payload) {
    context.commit("setSelectedProductQuantity", payload);
  },

  /**
   * It changes productTypes state
   *
   * @param {object} context products store context
   * @param {boolean} payload products productType value
   */
  changeProductTypes(context, payload) {
    context.commit("setProductTypes", payload);
  },

  /**
   * It changes showProductModal state
   *
   * @param {object} context products store context
   * @param {boolean} payload products showProductModal value
   * @returns {void}
   */
  changeShowProductModal(context, payload) {
    context.commit("setShowProductModal", payload);
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
