/**
 * ```
 * @prettier
 * ```
 */

/**
 * Components
 */
import CategoriesList from "@/components/categories-list/CategoriesListComponent.vue";
import Cart from "@/components/cart/CartComponent.vue";
import ProductsList from "@/components/products-list/ProductsListComponent.vue";
import AdminMenu from "@/components/admin-menu/AdminMenuComponent.vue";
import AdminSearchBar from "@/components/admin-search-bar/AdminSearchBarComponent.vue";

/**
 * Mixins
 */
import CartMixin from "@/mixins/cartMixin.js";
import UserMixin from "@/mixins/userMixin.js";
import ProductMixin from "@/mixins/productMixin.js";

/**
 * Services
 */
import ProductsService from "@/api/services/resources/products.js";

export default {
  name: "HomeView",

  components: {
    CategoriesList,
    Cart,
    ProductsList,
    AdminMenu,
    AdminSearchBar,
  },

  mixins: [CartMixin, UserMixin, ProductMixin],

  /**
   * @inheritdoc
   */
  data() {
    return {
      mobile: {
        categoriesOpen: false,
        cartOpen: false,
      },
      loading: {
        products: true,
      },
      categories: null,
      productsCount: 0,
      selectedCategoryId: null,
      originalCategories: null,
      partialPendingCategoriesToLoad: [],
    };
  },
  watch: {
    activeCategoryLoaded(value) {
      if (value === false) {
        return;
      }

      if (this.$store.getters["product/getAllProductsLoaded"]) {
        return;
      }

      if (value === false) {
        return;
      }

      //loading remaining visible products
      this.$store.dispatch("product/changeRemainingVisibleCategories", true);
    },
    activeCategoryState(value) {
      if (value === false) {
        return;
      }
      // do someething when active category selected
    },
  },
  /**
   * @inheritdoc
   */
  async created() {
    this.$store.dispatch("product/changeLoadingState", true);

    /**
     * It starts the call chain for products
     */
    let tmpcategories = await ProductsService.getCategories();

    if (this.$store.getters["global/getIsBulk"]) {
      /**
       * Find packaging category index
       */
      let packagingIndex = tmpcategories.findIndex(
        (category) => category.name.toLowerCase() === "packaging"
      );

      /**
       * Set packaging category as the last item
       */
      tmpcategories.splice(
        tmpcategories.length - 1,
        0,
        tmpcategories.splice(packagingIndex, 1)[0]
      );

      /**
       * Find packaging inserts category index
       */
      let packagingInsertsIndex = tmpcategories.findIndex(
        (category) => category.name.toLowerCase() === "packaging inserts"
      );

      /**
       * Set packaging inserts category as the last item
       */
      tmpcategories.splice(
        tmpcategories.length - 1,
        0,
        tmpcategories.splice(packagingInsertsIndex, 1)[0]
      );
    }

    this.categories = tmpcategories;

    this.$store.dispatch(
      "product/changeAllTotalCategories",
      this.getCountTotalCategories(this.categories)
    );

    this.$store.dispatch(
      "global/changeActiveCategoryIdState",
      tmpcategories[0].id
    );
    this.$store.dispatch("global/changeActiveCategoryState", true);
    this.$store.dispatch("global/changeActiveCategoryLoadedState", false);
    this.$store.dispatch("product/changeRemainingVisibleCategories", false);
    this.$store.dispatch("product/changeProductTypes", this.categories);
  },

  computed: {
    /**
     * It gives activeCategoryLoaded status
     *
     * @returns {boolean} Returns activeCategoryState status
     */
    activeCategoryLoaded() {
      return this.$store.getters["global/getActiveCategoryLoaded"];
    },

    /**
     * It gives activeCategoryState status
     *
     * @returns {boolean} Returns activeCategoryState status
     */
    activeCategoryState() {
      return this.$store.getters["global/getActiveCategory"];
    },

    /**
     * It gives isBulk status
     *
     * @returns {boolean} Returns isBulk status
     */
    isBulk() {
      return this.$store.getters["global/getIsBulk"];
    },

    /**
     * It gives canAccessAdminMenu status
     *
     * @returns {boolean} Returns canAccessAdminMenu status
     */
    canAccessAdminMenu() {
      return this.$store.getters["admin/getCanAccessAdminMenu"];
    },

    /**
     * It returns the AdminMenuPosition
     *
     * @returns {string}  AdminMenuPosition
     */
    getAdminMenuPosition() {
      return this.$store.getters["admin/getAdminMenuPosition"];
    },

    /**
     * it returns true if in mobile mode
     *
     * @returns {boolean} mobile mode
     */
    isMobileMode() {
      return this.$store.getters["global/getWindowWidth"] < 1024;
    },

    /**
     * it returns true if cart is open
     *
     * @returns {boolean} is cart open
     */
    isCartOpen() {
      return this.$store.getters["global/getIsCartOpen"];
    },

    /**
     * it returns true if categories is open
     *
     * @returns {boolean} is cart open
     */
    isCategoriesOpen() {
      return this.$store.getters["global/getIsCategoriesOpen"];
    },
  },

  methods: {
    /**
     * get Total categories
     *
     * @param {Array} categories all categories obj
     * @returns {number} total categories
     */
    getCountTotalCategories(categories) {
      let totalLoadedCategories = 0;
      categories.forEach((category) => {
        totalLoadedCategories++;

        if (category.subCategories.length > 0) {
          totalLoadedCategories += this.getCountTotalCategories(
            category.subCategories
          );
        }
      });
      return totalLoadedCategories;
    },

    /**
     * It checks if all products are loaded
     *
     * @returns {void}
     */
    allProductsAreLoaded() {
      return this.$store.getters["product/getAllProductsLoaded"];
    },

    /**
     * It retrieves selected category ID from
     * category list component
     *
     */
    loadProductCategory() {
      this.$store.dispatch("global/changeIsCategoriesOpen", false);
    },

    /**
     * Sets categories to search result
     *
     * @param {Array} result filtered categories / products
     * @returns {void}
     */
    onProductSearch(result) {
      this.categories = result;
    },

    /**
     * Hides or shows the categories
     *
     * @returns {void}
     */
    toggleCategoriesMenu() {
      this.$store.dispatch(
        "global/changeIsCategoriesOpen",
        !this.isCategoriesOpen
      );
      this.$store.dispatch("global/changeIsCartOpen", false);
    },

    /**
     * Hides or shows the cart
     *
     * @returns {void}
     */
    toggleCartMenu() {
      this.$store.dispatch("global/changeIsCartOpen", !this.isCartOpen);
      this.$store.dispatch("global/changeIsCategoriesOpen", false);
    },
  },
};
