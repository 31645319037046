/**
 * ```
 * @prettier
 * ```
 */

/**
 * Components
 */
import CheckoutContactDetails from "@/components/checkout-contact-details/CheckoutContactDetailsComponent.vue";
import CheckoutRequestInformation from "@/components/checkout-request-information/CheckoutRequestInformationComponent.vue";
import CheckoutSizingModal from "@/components/checkout-sizing-modal/CheckoutSizingModalComponent.vue";

/* Services */
import FileService from "@/api/services/utils/file.js";

/**
 * Mixins
 */
import CartMixin from "@/mixins/cartMixin.js";
import OrderMixin from "@/mixins/orderMixin.js";

/**
 * Checkout Information Component
 */
export default {
  components: {
    CheckoutContactDetails,
    CheckoutRequestInformation,
    CheckoutSizingModal,
  },

  mixins: [CartMixin, OrderMixin],

  /**
   * @inheritdoc
   */
  data() {
    return {
      activePanel: "aboutProject",
      isOrderSuccessfull: false,
      showApparelBreakdownModal: false,
      orderNoteFields: {
        number_of_packs: "",
        budget_per_pack: "",
        date_needed: "",
        shipping_to: "",
        other: "",
      },
      orderFields: {
        email: "",
        file: [],
        first_name: "",
        last_name: "",
        phone: "",
        company: "",
      },
      loading: {
        creatingOrder: false,
      },
    };
  },

  created() {
    this.prefillUserDetails();
  },

  /**
   * @inheritdoc
   */
  mounted() {
    this.fileService = new FileService();
  },

  methods: {
    /**
     * Displays a panel
     *
     * @param {string} panel - panel to be shown
     * @returns {void}
     */
    showPanel(panel) {
      this.activePanel = panel;
    },

    /**
     * Prefills orderFields with users data
     *
     * @returns {void}
     */
    prefillUserDetails() {
      const user = this.$store.getters["user/getUser"];
      this.orderFields.email = user.email;
      this.orderFields.first_name = user.firstName;
      this.orderFields.last_name = user.lastName;

      const billingAddress = this.$store.getters["user/getBillingAddress"];
      if (Object.keys(billingAddress).length > 0) {
        this.orderFields.phone = billingAddress.phone;
        this.orderFields.company = billingAddress.company;
      }
    },

    /**
     * Create a new order as a draft
     *
     * @param {boolean} checkSizing -> if true it will check if sizing is needed
     * @returns {void}
     */
    async checkout(checkSizing = true) {
      try {
        // check if cart items has sizing
        const hasSizing = this.$store.getters["cart/getCart"].items.some(
          (item) => {
            return item.product.hasSizing;
          }
        );
        if (hasSizing && checkSizing && !this.inBulkMode()) {
          this.showApparelBreakdownModal = true;
          return;
        }
        this.showApparelBreakdownModal = false;

        this.loading.creatingOrder = true;
        if (process.env.VUE_APP_GUEST_CHECKOUT == true && this.isGuest()) {
          await this.exportGuestCart(); //create woocomm cart
        }

        const payload = {
          email: this.orderFields.email,
          file: this.orderFields.file,
          first_name: this.orderFields.first_name,
          last_name: this.orderFields.last_name,
          phone: this.orderFields.phone,
          company: this.orderFields.company,
        };

        // orderMixin
        this.createOrder(payload, this.orderNoteFields);
      } catch (error) {
        this.loading.creatingOrder = false;
      }
    },

    /**
     * called when order is sucessfull via
     * apparel modal. Need to emit isSuccessfull to
     * parent to complete checkout process
     *
     * @returns {void}
     */
    apparelOrderSuccessfull() {
      this.$bvModal.hide("modal-apparel-breakdown");
      this.$emit("isOrderSuccessfull", true);
    },
  },
};
