/**
 * ```
 * @prettier
 * ```
 */

import AbstractApi from "../abstract-api";
import axios from "@/plugins/axios.js";
import qs from "qs";

/**
 * Models
 */
import Category from "@/api/models/category";
import Product from "@/api/models/product.js";
import ProductVariation from "@/api/models/product-variation.js";
import ProductVendor from "@/api/models/product-vendor";

/**
 * It gets API references
 */
const abstractApi = new AbstractApi();
const wpRef = abstractApi.getWpRef();

const ProductsService = {
  /**
   * Get all products according to queryParams
   *
   * @param {object} queryParams Params with Search string/SortBy/OrderBy etc
   * @example queryParams: {
   *            exclude:  {array} , Ensure result set excludes specific IDs.
   *            include:  {array} , Limit result set to specific ids
   *            orderby:  {string}, Sort collection by object attribute. Options: date, id, include, title and slug. Default is date.
   *            per_page: {number}, Number of items to be retrieved (Default:10)
   *            search:   {string}, Param for filtering
   *            sku:      {string}, Limit result set to products with a specific SKU.
   *            swag_qty: {number}, Quantity to retrive price from (Default:100)
   *          }
   * @returns {Promise} Products index promise
   */
  index(queryParams = {}) {
    const apiUrl = `${wpRef}swaghut/shop/v1/products`;
    const reqParams = {
      params: queryParams,
    };
    return axios.get(apiUrl, reqParams).then((response) => {
      const responseProducts = response.data.data.items;

      const products = Array();
      responseProducts.forEach((product) => {
        product.cId = product.id;
        product.cCategory = reqParams.params.category;
        products.push(Object.assign(new Product(), product));
      });
      response.data = products;

      return response;
    });
  },

  /**
   * Retrieve a product by id
   *
   * @deprecated pending to be removed
   * @param {number} id Product ID to be shown
   * @returns {Promise} Products show promise
   */
  show(id) {
    const apiUrl = `${wpRef}wc-proxy/v3/products/${id}`;
    return axios.get(apiUrl).then((response) => {
      const product = Object.assign(new Product(), response.data);
      return product;
    });
  },

  /**
   * Retrieve a product by id
   *
   * @param {number} id Product ID to be shown
   * @returns {Promise} Products show promise
   */
  async getProduct(id) {
    const apiUrl = wpRef + `swaghut/shop/v1/product`;
    const reqParams = {
      params: {
        id: id,
      },
    };

    return await axios.get(apiUrl, reqParams).then((response) => {
      return response.data.data.product;
    });
  },

  /**
   * Get a specific product variations by the parent product id
   *
   * @param {number} id Product ID to be shown
   * @returns {Promise} Variations by product id promise
   */
  variationsByProductId(id) {
    const apiUrl = wpRef + `wc-proxy/v3/products/${id}/variations?per_page=100`;
    return axios.get(apiUrl).then((response) => {
      if (!response.data) {
        return [];
      }

      let variations = [];

      for (const [key, product] of Object.entries(response.data)) {
        if (key === "custom_options") {
          continue;
        }

        variations.push(Object.assign(new ProductVariation(), product));
      }

      return variations;
    });
  },

  /**
   * Get a parent product variable
   *
   * @param {number} id Product ID to get price
   * @returns {object} The variation product or null if no variation
   */
  async productVariable(id) {
    const apiUrl = wpRef + `swaghut/shop/v1/product`;
    const reqParams = {
      params: {
        id: id,
      },
    };
    return await axios.get(apiUrl, reqParams).then((response) => {
      const product = response.data.data.product;

      if (!product.defaultVariation || !product.defaultVariation.id) {
        return null;
      }
      return {
        defaultVariationId: product.defaultVariation.id,
        product: response.data.data.product,
      };
    });
  },

  /**
   * Get default product variation
   *
   * @param {number} id Product ID to get price
   * @returns {object} The variation product or null if no variation
   */
  async getVariationById(id) {
    const apiUrl = wpRef + `swaghut/shop/v1/product`;
    const reqParams = {
      params: {
        id: id,
      },
    };
    return await axios.get(apiUrl, reqParams).then((response) => {
      let variation = response.data.data.product;
      if (Object.keys(variation).length === 0) {
        return null;
      }
      return response.data.data.product;
    });
  },

  /**
   * Get product variation by attributes
   *
   * @param {object} params payload
   * @returns {object} The variation product or null if no variation
   */
  async productVariationByAttributes(params) {
    const apiUrl = wpRef + `swaghut/shop/v1/product/variation`;
    const reqParams = {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false });
      },
    };

    return await axios.get(apiUrl, reqParams).then((response) => {
      let variation = response.data.data.product;
      if (Object.keys(variation).length === 0) {
        return null;
      }
      return response.data.data.product;
    });
  },

  /**
   * Get all swag categories
   *
   * @returns {Promise<Category[]>} Categories
   */
  getCategories() {
    const api = wpRef + "swaghut/shop/v1/product/category?slug=swag";

    return axios.get(api).then((response) => {
      const responseCategories = response.data.data.result.children;
      const categories = Array();
      responseCategories.forEach((item) => {
        const category = new Category();

        category.id = item.id;
        category.name = item.name;
        category.image = item.image;
        category.slug = item.slug;
        category.parentId = item.parent.id || null;
        category.description = item.description;
        category.subCategories = item.children;

        categories.push(category);
      });
      return categories;
    });
  },

  /**
   * Gets a product vendor details
   *
   * @param {Array} ids product id
   * @returns {Promise} Product vendor promise
   */
  getProductVendorDetails(ids) {
    const apiUrl = `${wpRef}shop/v1/product`;
    return axios
      .get(apiUrl, {
        params: {
          products: ids.join(","),
        },
      })
      .then((response) => {
        const products = Array();
        response.data.products.forEach((product) => {
          products.push(
            new ProductVendor(
              product.id,
              product.parentId,
              product.supplierInfo ? product.supplierInfo.name : "",
              product.supplierInfo ? product.supplierInfo.code : "",
              product.supplierInfo ? product.supplierInfo.link : "",
              product.supplierInfo ? product.supplierInfo.stockSample : "",
              product.hsCode,
              product.countryOrigin,
              product.supplier ? product.supplier.name : "",
              product.supplier ? product.supplier.email : "",
              product.supplier ? product.supplier.phone : "",
              product.sku,
              product.name,
              product.attributes,
              product.prices,
              product.enablePriceTable,
              product.price
            )
          );
        });

        return products;
      })
      .catch((error) => {
        throw new Error(
          error.response ? error.response.data.message : error.message
        );
      });
  },

  /**
   * Gets internal pricing of a product
   * TODO: pending to be removed
   *
   * @param {number} id product id
   * @param {number} quantity quantity of product
   * @returns {Promise} Product vendor promise
   */
  getPrices(id, quantity) {
    const apiUrl = `${wpRef}swaghut/shop/v1/product`;
    return axios
      .get(apiUrl, {
        params: {
          id: id,
          quantity,
        },
      })
      .then((response) => {
        return response.data.price;
      });
  },
};

export default ProductsService;
