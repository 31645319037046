// Utils
import { sortSizes } from '@/utils/sizingHelper';

export default {
  name: 'VariationSizeComponent',
  props: {
    variation: {
      type: Object | null,
      default: null,
    },
    defaultSizingQuantity: {
      type: Object | null,
      default: null,
    },
    quantityNeeded: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sizes: [],
    };
  },
  created() {
    this.initializeSizes();
  },
  computed: {
    /**
     * Returns selected amount
     *
     * @returns {string} -> title
     **/
    getSelectedAmountText() {
      if (this.quantityNeeded) {
        return `selected ${this.getTotalQuantity()} of ${this.quantityNeeded}`;
      }

      return `${this.getTotalQuantity()} selected`;
    },
  },
  methods: {
    /**
     * Initializes sizes array, gives each size a quantity of 0
     * by default
     *
     * @returns {void}
     */
    initializeSizes() {
      if (!this.variation || !this.variation.options) {
        return;
      }

      // Initialize sizes array
      const sizes = this.variation.options.map((option) => {
        let value = 0;
        // set default quantity if it exists
        if (this.defaultSizingQuantity && this.defaultSizingQuantity[option.name]) {
          value = this.defaultSizingQuantity[option.name];
        }

        return {
          slug: option.slug,
          name: option.name,
          value,
        };
      });

      // order sizes by name
      this.sizes = sortSizes(sizes, 'name');

      this.onQuantityChanged();
    },

    /**
     * Emits quantityChanged event when quantity of a size is changed
     *
     * @returns {void}
     **/
    onQuantityChanged() {
      const totalQuantity = this.getTotalQuantity();

      const formatted = {};
      this.sizes.forEach((size) => {
        formatted[size.name] = size.value;
      });

      const dataToEmit = {
        sizesFormatted: formatted,
        sizes: this.sizes,
        totalQuantity
      };
      if (this.quantityNeeded) {
        dataToEmit.isQuantityValid = totalQuantity === this.quantityNeeded;
      }

      this.$emit('onSelectedSizes', dataToEmit);
    },

    /**
     * Returns total quantity of all sizes
     *
     * @returns {number} -> total quantity
     **/
    getTotalQuantity() {
      return this.sizes.reduce((total, size) => {
        return total + Number(size.value);
      }, 0);
    },
  },
};
