/**
 * ```
 * @prettier
 * ```
 */

// Components
import CartHeader from "@/components/cart-header/CartHeaderComponent.vue";
import CartFooter from "@/components/cart-footer/CartFooter.vue";
import CartItem from "@/components/cart-item/CartItemComponent.vue";

// Mixins
import CartMixin from "@/mixins/cartMixin.js";
import OrderMixin from "@/mixins/orderMixin.js";

export default {
  name: "CartComponent",
  components: {
    CartHeader,
    CartFooter,
    CartItem,
  },

  mixins: [CartMixin, OrderMixin],

  methods: {
    /**
     * It returns the cart title
     *
     * @returns {string} Cart title
     **/
    getCartTitle() {
      return this.inBulkMode() ? " Swag Items" : "Pack Content";
    },
  },
};
