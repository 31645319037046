/**
 * ```
 * @prettier
 * ```
 */

import CategoryListItem from "@/components/category-list-item/CategoryListItemComponent.vue";
/**
 * Mixins
 */
import ProductMixin from "@/mixins/productMixin.js";

export default {
  components: {
    CategoryListItem,
  },
  mixins: [ProductMixin],
  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.jumpToCategory();
  },
  methods: {
    /**
     * Handle the jumpToCategory param
     * Allows external sites to jump to a specific category
     *
     * @returns {void}
     */
    jumpToCategory() {
      const id = Number(this.$route.query.jumpToCategory);
      if (!id) {
        return;
      }

      if (this.categories) {
        const category = this.categories.find((category) => category.id === id);
        if (category.loadingFinished) {
          this.selectCategory(category);
          return;
        }
      }

      // wait 1 second and try again
      setTimeout(() => {
        this.jumpToCategory();
      }, 1000);
    },

    /**
     * It selects the category
     *
     * @param {object} category Selected category
     */
    selectCategory(category) {
      this.$store.dispatch("global/changeActiveCategoryIdState", category.id);
      this.$store.dispatch("global/changeActiveCategoryState", true);
      this.$store.dispatch("global/changeActiveCategoryLoadedState", false);
      this.$store.dispatch("product/changeRemainingVisibleCategories", false);

      var VueScrollTo = require("vue-scrollto");
      if (this.$store.getters["product/getAllProductsLoaded"]) {
        VueScrollTo.scrollTo("#" + category.slug);
      } else {
        VueScrollTo.scrollTo("#" + category.slug, 0);
      }

      this.$emit("sendCategoryId");

      // Google Analytics Event
      this.$gtag.event("Inform", {
        event_category: "Packbuilder - Inform",
        event_label: category.name,
      });

      // emit to parent window
      // we can use wildcard as origin because we are not sending any sensitive data
      window.parent.postMessage(
        {
          type: "category",
          category: {
            id: category.id,
            name: category.name,
            slug: category.slug,
          },
        },
        "*"
      );
    },
  },
};
