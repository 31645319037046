/**
 * ```
 * @prettier
 * ```
 */

const filters = {
  symbol: "€",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
};

export default filters;
