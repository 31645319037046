/**
 * ```
 * @prettier
 * ```
 */

import AbstractApi from "../abstract-api";
import axios from "@/plugins/axios.js";

const abstractApi = new AbstractApi();
const wpRef = abstractApi.getWpRef();

const CartService = {
  /**
   * returns true if cart is in
   * pack mode. Pack Mode by default is disabled
   *
   * @returns {Promise<boolean>} is pack mode
   */
  async isPackMode() {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart/pack-mode`;
    const response = await axios.get(apiUrl);
    return response.data.data.packMode;
  },

  /**
   * Sets the cart into pack mode
   *
   * @param {boolean} enabled _
   * @returns {Promise<boolean>} is pack mode
   */
  async setPackMode(enabled) {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart/pack-mode`;
    const response = await axios.put(apiUrl, { pack_mode: +enabled });
    return response.data.data.packMode;
  },

  /**
   * Gets the total amount of packs
   * in the cart (pack quantity)
   *
   * @returns {Promise<number>} pack quantity
   */
  async getPackQuantity() {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart/pack-quantity`;
    const response = await axios.get(apiUrl);
    return response.data.data.packQuantity;
  },

  /**
   * Sets the total amount of packs
   * in the cart (pack quantity)
   *
   * @param {number} quantity - new pack quantity
   * @returns {Promise<boolean>} true if successful
   */
  async setPackQuantity(quantity) {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart/pack-quantity`;
    const response = await axios.put(apiUrl, { quantity });
    return response.data.success;
  },

  /**
   * Gets the users cart
   *
   * @returns {Promise<any>} - users cart
   */
  async getCart() {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart`;
    const response = await axios.get(apiUrl);
    return response.data.data.cart;
  },

  /**
   * Adds item to users cart
   *
   * @param {number} productId - ID of product to add
   * @param {number} quantity - Quantity of product (per pack)
   * @param {object} variation - Product variations
   * @param {object} sizes - Product sizes
   * @returns {Promise<number>} - Cart Item Key
   */
  async addToCart(productId, quantity, variation = {}, sizes = {}) {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart`;
    const response = await axios.post(apiUrl, {
      product: productId,
      quantity: quantity,
      variation,
      quantity_per_size: sizes,
    });
    return response.data.data.cartItemKey;
  },

  /**
   * Adds bundle to users cart
   *
   * @param {number} bundleId - ID of bundle
   * @returns {Promise<number>} - Cart Item Key
   */
  async addBundleToCart(bundleId) {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart`;
    const response = await axios.post(apiUrl, {
      product: bundleId,
    });
    return response.data.data.cartItemKey;
  },

  /**
   * Updates item in users cart
   *
   * @param {string} key - cart item key
   * @param {number} productId - ID of product to add
   * @param {number} quantity - Quantity of product (per pack)
   * @param {object} variation - Product variations
   * @param {object} sizes - Product sizes
   * @returns {Promise<number>} - Cart Item Key
   */
  async updateCartItem(key, productId, quantity, variation = {}, sizes = {}) {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart`;
    const response = await axios.put(apiUrl, {
      key,
      product: productId,
      quantity: quantity,
      variation,
      quantity_per_size: sizes,
    });
    return response.data.data.cartItemKey;
  },

  /**
   * Removes cart item
   *
   * @param {string} key - cart item key
   * @returns {boolean} - true if deleted
   */
  async removeCartItem(key) {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart`;
    const response = await axios.delete(apiUrl, {
      data: {
        key,
      },
    });
    return response.data.data.deleted;
  },

  /**
   * Clears users cart
   *
   * @returns {void}
   */
  async clearCart() {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart/clear`;
    const response = await axios.delete(apiUrl);
    return response.data.success;
  },

  /**
   * Transfers the cookie cart (guest) to a users cart
   *
   * @returns {Promise<any>} - users cart
   */
  async transferCart() {
    const apiUrl = `${wpRef}swaghut/shop/v1/cart`;
    const response = await axios.get(apiUrl, { withCredentials: true });
    return response.data.data.cart;
  },
};

export default CartService;
