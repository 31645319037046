/**
 * ```
 * @prettier
 * ```
 */

export default class ProductPricingRule {
  constructor(from = null, to = null, type = null, amount = null) {
    this.from = from;
    this.to = to;
    this.type = type;
    this.amount = amount;
  }

  /**
   * Parse an list of json object to a list of
   * ProductPricingRule objects
   *
   * @param {Array} items The array of json objects
   * @returns {Array} The array of ProductPricingRule objects
   */
  static fromArray(items) {
    if (!items) {
      return [];
    }

    return items.map((item) => {
      return Object.assign(new ProductPricingRule(), item);
    });
  }

  /**
   * Get the label for th pricing rule to be displayed in the view.
   *
   * @returns {string} The label to be displayed in the view.
   */
  get label() {
    if (this.to && this.from) {
      return `${this.from} - ${this.to}`;
    }

    return this.to;
  }
}
