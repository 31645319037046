export default {
  name: 'RequestSampleComponent',
  props: {
    productName: {
      type: String,
      default: '',
    },
    productSku: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * @inheritdoc
   */
  data() {
    return {
      modalOpen: false,
      formId: `request-sample-form-${this._uid}`
    };
  },
  watch: {
    modalOpen() {
      if (!this.modalOpen) {
        return;
      }
      this.loadForm();
    },
  },
  mounted() {
    this.insertScript();
  },
  methods: {
    /**
     * Create the script tag to load the HubSpot form library.
     * Adds the script tag to the head of the document.
     * 
     * @returns {void} 
     */
    insertScript() {
      // If the script is already loaded, don't load it again.
      if (window.hbspt) {
        return;
      }

      let script = document.createElement('script');
      script.setAttribute('charset', 'utf-8');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', '//js.hsforms.net/forms/shell.js');
      document.head.appendChild(script)
    },

    /**
     * Load the HubSpot form.
     * 
     * @returns {void}
     */
    async loadForm() {
      await window.hbspt.forms.create({
        region: "na1",
        portalId: "8927223",
        formId: "f9c9493d-8f47-445a-b081-8a034c2eaecf",
        target: `#${this.formId}`,
        onFormReady: (form) => {
          // Add the Ant Design classes to the form button.
          const button = form.querySelector('.hs-button');
          button.classList.add('ant-btn', 'ant-btn-primary', 'ant-btn-lg');

          // get the product name and sku fields
          const productNameInput = form.querySelector('input[name="product_name"]');
          const productSkuInput = form.querySelector('input[name="product_sku"]');

          // add product name and sku to fields
          productNameInput.value = this.productName;
          productSkuInput.value = this.productSku;
        },
      });
    },
  },
};
