/**
 * Components
 */
import ProductItemsPartial from "@/components/product-items-partial/ProductItemsPartialComponent.vue";

/**
 * Mixins
 */
import ProductMixin from "@/mixins/productMixin.js";

/**
 * Products category Component
 *
 */
export default {
  name: 'ProductsCategory',
  components: {
    ProductItemsPartial,
  },
    mixins: [ProductMixin],
  props: {
    categoriesCount: {
      type: Number,
      required: true,
    },
    category_index: {
      type: Number,
      required: true,
    },
    categories: {
      default: [],
    },
    category: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    isSubcategory: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  /**
   * @inheritdoc
   */
  data() {
    return {
      itemRefs: {},
    };
  },
  created() {
    this.category.isSubcategory = this.isSubcategory;
  },
  mounted() {
    const observerOptions =
      {
        root: null,
        rootMargin: "0px",
        thredhold: 0.5,
      } || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect", this.category.id, this.category.name, this.category_index);
      }
    }, observerOptions);

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
    watch: {
    remainingVisibleCategories(value) {
        
      if (this.$store.getters["product/getAllProductsLoaded"]) {
        return;
      }
      
      if (this.category.loadingFinished === true) {
        return;
      }
      
      if (value === false) {
        return;
      }
      
      //find selected category obj
      let catInfo = this.getCategoryobj(this.categories, {}, this.$store.getters["global/getActiveCategoryId"] );
      
      let matchParentId = this.$store.getters["global/getActiveCategoryId"];
      
      
      //load only parent categories
      if (catInfo.obj.isSubcategory === true) {
        matchParentId = catInfo.obj.parentId;
      } 
        
      let catSelectedIndex = this.categories.findIndex(
              (category) => category.id === matchParentId
            );
            
            let catCurrentIndex = this.categories.findIndex(
              (category) => category.id === this.category.id
            );

      //for parents
      if (
         catCurrentIndex > catSelectedIndex
      ) {
        if (typeof this.$refs[`category_obj_${this.category.id}`].getProducts === 'function') {    
            this.$refs[`category_obj_${this.category.id}`].getProducts();
        }
        return;
      }
        
      // load sections from selected subcategory
        if (catInfo.obj.isSubcategory === true && 
          this.category.parentId === catInfo.obj.parentId && 
          this.category_index > catInfo.index
          ) {
         
        
        if (typeof this.$refs[`category_obj_${this.category.id}`].getProducts === 'function') {    
            this.$refs[`category_obj_${this.category.id}`].getProducts();
        } 
          return;
      }
      
      //when parent category is selected
        if (catInfo.obj.isSubcategory === false &&
        this.category.parentId === catInfo.obj.id && 
        catInfo.obj.subCategories.length > 0) { 
          if (typeof this.$refs[`category_obj_${this.category.id}`].getProducts === 'function') {    
              this.$refs[`category_obj_${this.category.id}`].getProducts();
          }
          
          return;
        }
      
      // when subcategory is below parent of selected parent category
      if (this.category.isSubcategory === true) {
        let currentParentId = this.category.parentId;
        let selectedParentId = (catInfo.obj.isSubcategory === true)?catInfo.obj.parentId: catInfo.obj.id;
        let currentParentObj = this.getCategoryobj(this.categories, {}, currentParentId );
        let selectedParentObj = this.getCategoryobj(this.categories, {}, selectedParentId );
        
        if (currentParentObj.index > selectedParentObj.index ) { 
          if (typeof this.$refs[`category_obj_${this.category.id}`].getProducts === 'function') {    
              this.$refs[`category_obj_${this.category.id}`].getProducts();
          }
        }
        
        
      } 
      
      
      
      
    },
  },
  beforeDestroy() {},
  computed: {
    remainingVisibleCategories() {
      return this.$store.getters["product/getRemainingVisibleCategories"];
    },
    styles: function () {
      
      if (this.$store.getters["product/getAllProductsLoaded"]) {
        return {
          opacity: 1,
        };
      }
      
      if (this.category.isSubcategory === true) {
        return {
          opacity: 1,
        };
     } 
      
      if (
        this.category.loadingFinished === false &&
        this.$store.getters["global/getActiveCategory"] === true &&
        this.$store.getters["global/getActiveCategoryId"] !== this.category.id
      ) {
        
        return {
          opacity: 0,
        };
      }
      
      return {
          opacity: 1,
      };
      
    },
  },
 
  methods: {
  
    /**
     * It performs requests according to what is shown on the view
     *
     * @param {boolean} isVisible Whether category is visible or not
     * @param {object} entry View properties
     * @param {object} category Visible category
     * @returns {void}
     */
    viewChanged(isVisible, entry, category) {
 
      category.isVisible = isVisible;
      
      if (category.isVisible === true 
      ) {
        
        if (typeof this.$refs[`category_obj_${this.category.id}`].getProducts === 'function') {    
           this.$refs[`category_obj_${this.category.id}`].getProducts();
        }
      }
      
      if (category.loadingStarted) {
        category.menuIsActive = isVisible;
      }
    },
    
  },
};
