/**
 * ```
 * @prettier
 * ```
 */

/**
 * This file, related to the nuxt store, in responsible for
 * the storage of available modules. As this is used in multiple files,
 * it is pointless and wasteful potentially doing dozens of calls
 * when one can be done and it is then stored in here.
 */
const state = {
  cart: {
    items: [],
    itemsCount: 0,
    costPerPack: 0,
    totalCost: 0,
    packQuantity: process.env.VUE_APP_DEFAULT_PACK_QTY
      ? Number(process.env.VUE_APP_DEFAULT_PACK_QTY)
      : 100,
  },
  loading: false,
  variations: [], // To Remove
  sizingQuantity: {},
};

const getters = {
  /**
   * It gets cart information
   *
   * @param {object} state state
   * @returns {Array} It returns the cart data
   */
  getCart(state) {
    return state.cart;
  },

  /**
   * It checks if cart is empty
   *
   * @param {object} state state
   * @returns {boolean} true if cart is empty
   */
  getIsCartEmpty(state) {
    return state.cart.itemsCount === 0;
  },

  /**
   * It gets loading
   *
   * @param {object} state state
   * @returns {boolean} Loading state
   */
  getLoading(state) {
    return state.loading;
  },

  /**
   * It get pack quantity
   *
   * @param {object} state state
   * @returns {number} Pack quantity
   */
  getPackQuantity(state) {
    return state.cart.packQuantity;
  },

  /**
   * It gets all products' variations
   *
   * @param {object} state state
   * @returns {Array} It returns all variations
   */
  getVariations(state) {
    return state.variations;
  },

  /**
   * It gets sizing quantity
   *
   * @param {object} state state
   * @returns {object} It returns sizing quantity
   */
  getSizingQuantity(state) {
    return state.sizingQuantity;
  },
};

const mutations = {
  /**
   * It adds an item to the cart
   *
   * @param {object} state state
   * @param {object} item Item to be added to cart
   */
  addCartItem(state, item) {
    state.cart.items.push(item);
    state.cart.itemsCount++;
  },

  /**
   * It removes an item from the cart
   *
   * @param {object} state state
   * @param {number} key Item key to be removed
   * @returns {void}
   */
  removeCartItem(state, key) {
    state.cart.items.forEach((item, index, object) => {
      if (item.key == key) {
        object.splice(index, 1);
        state.cart.itemsCount--;
      }
    });
  },

  /**
   * Set loading variable
   *
   * @param {object} state state
   * @param {boolean} loading Loading value
   * @returns {void}
   */
  setLoading(state, loading) {
    state.loading = loading;
  },

  /**
   * Sets the cart items
   *
   * @param {object} state state
   * @param {Array} items cart items
   * @returns {void}
   */
  setPackItems(state, items) {
    state.cart.items = items;
    state.cart.itemsCount = items.length;
  },

  /**
   * Set pack quantity variable
   *
   * @param {object} state state
   * @param {number} quantity Pack quantity
   * @returns {void}
   */
  setPackQuantity(state, quantity) {
    state.cart.packQuantity = quantity;
  },

  /**
   * Sets the cost Per Pack
   *
   * @param {object} state state
   * @param {number} total cost Per Pack
   * @returns {void}
   */
  setCostPerPack(state, total) {
    state.cart.costPerPack = total;
  },

  /**
   * Sets the total Cost
   *
   * @param {object} state state
   * @param {number} total total Cost
   * @returns {void}
   */
  setTotalCost(state, total) {
    state.cart.totalCost = total;
  },

  /**
   * Set products' variations
   *
   * @param {object} state state
   * @param {object} variation Variations
   * @returns {void}
   */
  setVariation(state, variation) {
    state.variations = variation;
  },

  /**
   * adds a products' variations
   *
   * @param {object} state state
   * @param {object} variation Variation to be added
   * @returns {void}
   */
  addVariation(state, variation) {
    let newArray = state.variations.filter((item) => {
      return item.id != variation.id;
    });

    state.variations = newArray;
    state.variations.push(variation);
  },

  /**
   * Set sizing quantity
   *
   * @param {object} state state
   * @param {object} sizingQuantity Sizing quantity
   * @returns {void}
   */
  setSizingQuantity(state, sizingQuantity) {
    state.sizingQuantity = sizingQuantity;
  },
};

const actions = {
  /**
   * It adds a new product item to the cart
   *
   * @param {object} context Cart store context
   * @param {object} payload Product item to added
   */
  addItemToCart(context, payload) {
    context.commit("addCartItem", payload);
  },

  /**
   * It changes loading state
   *
   * @param {object} context Cart store context
   * @param {boolean} payload Cart loading new status
   */
  changeLoadingState(context, payload) {
    context.commit("setLoading", payload);
  },

  /**
   * It changes pack quantity
   *
   * @param {object} context Cart store context
   * @param {number} payload Pack quantity
   */
  changePackQuantity(context, payload) {
    const minQuantity = Number(process.env.VUE_APP_DEFAULT_PACK_QTY);
    if (payload < minQuantity) {
      payload = minQuantity;
    }

    context.commit("setPackQuantity", payload);
  },

  /**
   * It cleans up cart localStorage and state
   *
   * @param {object} context Cart store context
   */
  cleanUpCart(context) {
    const cart = {
      items: [],
      itemsCount: 0,
      costPerPack: 0,
      totalCost: 0,
      packQuantity: context.getters.getPackQuantity,
    };
    context.commit("setVariation", []);

    context.commit("setPackItems", cart.items);
    context.commit("setCostPerPack", cart.costPerPack);
    context.commit("setTotalCost", cart.totalCost);
  },

  /**
   * It stores cart details into the store and localStorage
   *
   * @param {object} context  Cart store context
   * @param {object} payload Cart details to be updated
   */
  setCart(context, payload) {
    context.commit("setPackItems", payload.items);
    context.commit("setPackQuantity", payload.packQuantity);
    context.commit("setCostPerPack", payload.costPerPack);
    context.commit("setTotalCost", payload.totalCost);
  },

  /**
   * It removes an item from the cart
   *
   * @param {object} context Cart store context
   * @param {number} key Key to be removed
   */
  removeCartItem(context, key) {
    context.commit("removeCartItem", key);
  },

  /**
   * The actions adds or updates a product variation
   *
   * @param {object} context Cart store context
   * @param {object} payload Variation to be updated
   */
  updateVariation(context, payload) {
    context.commit("addVariation", payload);
  },

  /**
   * Adds sizing quantity
   *
   * @param {object} context Cart store context
   * @param {object} payload sizing quantity to be updated
   */
  changeSizingQuantity(context, payload) {
    context.commit("setSizingQuantity", payload);
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
