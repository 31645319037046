/**
 * Services
 */
import ProductsService from "@/api/services/resources/products.js";

/**
 * Components
 */
import ProductItemObserver from "@/components/observer-common/ObserverCommonComponent.vue";
import ProductCard from "@/components/product-card/ProductCardComponent.vue";

/**
 * Mixins
 */
import ProductMixin from "@/mixins/productMixin.js";
import CartMixin from "@/mixins/cartMixin.js";

/* Other */
import _ from "lodash";

export default {
  components: {
    ProductItemObserver,
    ProductCard,
  },

  mixins: [ProductMixin, CartMixin],

  props: {
    category_index: {
      type: Number,
      required: true,
    },
    categories: {
      default: [],
    },
    category: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    isSubcategory: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    productList: [],
    page: 1,
    loadingMore: true,
    selectedCategoryIndex: 0,
    selectedCategoryId: 0,
    obsOptions: {
      root: null,
      rootMargin: "900px",
      thredhold: 0,
    },
  }),
  // good
  updated: _.debounce(function () {
      this.$nextTick(() => {
        this.category.categoryUpdated = true;
      })
  }, 250), // increase to ur needs
  created() {
    this.selectedCategoryIndex = this.category_index;
    this.selectedCategoryId = this.category.id;
    if (this.category.loadingFinished === true) {
      this.loadingMore = false;
      return;
    }
    this.category.domLoaded = false;
    this.category.products = [];
    this.category.isLoading = false;
  },
  
  destroyed() {},
  methods: {
    /**
     * product is valid
     *
     * @returns {boolean} product is valid
     */
    productIsValid() {

      if (this.loadingMore === false) {
        this.activeCategoryFinished();
        return false;
      }
      
      if (this.category.isLoading == true) {
        return false;
      }
      
      if (this.category.loadingFinished == true) {
        
        this.activeCategoryFinished();
        
        return false;
      }
      
      if ( this.$store.getters["global/getActiveCategory"] === true  && 
       parseInt(this.$store.getters["global/getActiveCategoryId"]) !== parseInt(this.category.id)
      ) {
       return false;
     
      }
          
          
      return true;
    },
    
    preGetProducts() {
      if (this.$store.getters["global/getActiveCategory"] === true) { 
        return; 
      }
      
      this.getProducts();
    },
    
    /**
     * It gets all categories' products assigning to the prop
     *
     * @returns {void}
     */
    getProducts() {
      
      if (!this.productIsValid()) { 
        return;
      }
       
           
      var productQueryParams = {
        per_page: 20,
        category: this.selectedCategoryId,
        page: this.page,
        orderby: "price",
        order: "asc"
      };

      this.category.isLoading = true;
      this.category.loadingStarted = true;
      this.category.loadingStopped = false;
       
      /**
       * Recursive API calls to be sequential
       */
      ProductsService.index(productQueryParams)
        .then((response) => {
        
          const lists = response.data;
          if (Object.keys(lists).length === 0) {
            this.afterProductLoading();
            return;
          }

          this.category.isLoading = false;
          this.productList = [...this.productList, ...lists];
          
          this.category.products = this.productList;
          this.category.loadingStopped = true;
         
          if (
            this.$store.getters["global/getActiveCategory"] == true &&
            this.$store.getters["global/getActiveCategoryId"] ==
              this.selectedCategoryId
          ) {
            var VueScrollTo = require("vue-scrollto");
            VueScrollTo.scrollTo(
              "#" + this.category.slug,
              0
            );
            
          }
          this.page++;
          
          this.getProducts();
          
        })
        .then(() => {})
        .catch(() => {
          this.afterProductLoading();
        });
    },
    
    
    
    /**
     * events once all products are loaded
     *
     * @returns {void}
     */
    afterProductLoading() {
      this.loadingMore = false;
      this.category.isLoading = false;
      this.category.loadingFinished = true;
 
      let totalCategories = this.$store.getters["product/getAllTotalCategories"];
      let totalLoadedCategories = this.getCountTotalLoadedCategories(this.categories);
      
      if (
        totalCategories > 3 &&
        parseInt(totalCategories) === parseInt(totalLoadedCategories) &&
        this.$store.getters["product/getAllProductsLoaded"] == false
      ) {
       
        this.$store.dispatch("product/changeAllProductsLoaded", true);
      }
      
      
      this.activeCategoryFinished();
      

    },
    
    activeCategoryFinished() {
      if (
        this.$store.getters["global/getActiveCategory"] == true &&
        this.$store.getters["global/getActiveCategoryId"] ==
          this.selectedCategoryId
      ) {
         
        this.$store.dispatch("global/changeActiveCategoryState", false);
        this.$store.dispatch("global/changeActiveCategoryLoadedState", true);
      }
    },
    
    
    
    getCountTotalLoadedCategories(categories) {
      let totalLoadedCategories = 0
      categories.forEach((category) => {
        if (category.loadingFinished === true) {
          totalLoadedCategories++;
        }
        if (category.subCategories.length > 0) { 
          totalLoadedCategories+= this.getCountTotalLoadedCategories(category.subCategories)
        }
      });
      return totalLoadedCategories;
    },
    
    /**
     * It shows up a modal with product details
     *
     * @param {object} product Product to be displayed
     */
    async showProduct(product) {
      // Set product loading state to true
      this.$store.dispatch("product/changeLoadingState", true);
      // Open product modal
      this.$store.dispatch("product/changeShowProductModal", true);

      // Set product param
     this.setProductParam(product.id);

      this.$store.dispatch(
          "product/changeSelectedProductQuantity",
          this.$store.getters["global/getIsBulk"]?0:1
        );
      this.$store.dispatch("product/changeSelectedProduct", product);
      const variable = await this.getVariableProduct(product);
      await this.refreshProduct(
        product,
        variable.product,
        variable.defaultVariationId
      );
      
      // Set product loading state to false
      this.$store.dispatch("product/changeLoadingState", false);

       // Google Analytics Event
       this.$gtag.event("Inform", {
        event_category: "Packbuilder - Inform",
        event_label: product.name,
      });
    }
  },
};
