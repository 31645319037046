/**
 * ```
 * @prettier
 * ```
 */

// Mixins
import CartMixin from "@/mixins/cartMixin.js";
import UserMixin from "@/mixins/userMixin.js";

// Services
import UserService from "@/api/services/resources/user";

export default {
  name: "AuthModalLogin",
  mixins: [CartMixin, UserMixin],
  data() {
    return {
      loading: {
        login: false,
      },
      error: "",
      form: {
        email: "",
        password: "",
      },
      formRules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Please insert a valid email address",
          },
        ],
        password: [
          {
            required: true,
            message: "Please insert your password",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Handles the loginForm submit event
     *
     * @returns {void}
     */
    onSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.login();
        }
      });
    },

    /**
     * Login user, Exports cart users
     * cart. Redirects user to checkout
     *
     * @returns {void}
     */
    async login() {
      try {
        this.loading.login = true;
        this.error = "";

        const response = await UserService.login({
          username: this.form.email,
          password: this.form.password,
          has_old_system: true,
        });

        // changes token
        this.$store.dispatch("user/changeToken", response.token);
        localStorage.setItem("token-override", response.token);

        // change guest mode
        this.$store.dispatch("user/changeIsGuest", false);

        // changes portal store
        const portal = response.user.getPortal();
        this.$store.dispatch("user/changePortalId", portal.site.id);

        // get user details
        await this.getUserDetails();

        // Gets cart
        this.exportGuestCart();

        // redirect to checkout
        this.loading.login = false;
        this.$router.push("/checkout");
      } catch (error) {
        this.loading.login = false;
        this.error = error;
      }
    },
  },
};
