// Plugins
import ntc from "@/plugins/ntc.js";

export default {
  props: {
    variation: {
      type: Object,
      default: null
    },
    selectedColor: {
      type: Object,
      default () {
        return {};
      }
    },
  },
  model: {
    prop: 'selectedColor',
    event: 'onSelectedColorChanged'
  },
  created() {
    this.initialiseSelectedColor();
  },
  methods: {
    /**
     * Sets the default selected color if exists
     * 
     * @returns {void}
     */
    initialiseSelectedColor() {
      if (!this.variation || !this.variation.options) {
        return;
      }

      const defaultColor = this.variation.options.find(option => {
        return option.slug === this.variation.selectedItem[0];
      });

      this.$emit("onSelectedColorChanged", defaultColor);
    },

    /**
     * It returns circle background color
     *
     * @param {string} color Color string
     * @returns {object} Background color object
     */
    getCircleBgColor(color) {
      return {
        "background-color": ntc.getHex(color),
      };
    },

    /**
     * Returns if color option is selected
     * 
     * @param {object} color Color option
     * @returns {boolean} true if selected
     */
    isColorSelected(color) {
      if (!this.selectedColor) {
        return false;
      }
      return this.selectedColor.slug === color.slug;
    },

    /**
     * It selects color option and emits it
     * back to parent component
     *
     * @param {string} option Color option
     * @returns {void}
     */
    onColorClick(option) {
      this.$emit("onSelectedColorChanged", option);
    }
  },
};
