/**
 * ```
 * @prettier
 * ```
 */

export default {
  name: "CategoryListItem",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showChildren: false,
    };
  },
  methods: {
    /**
     * It gets the category name to display
     *
     * @param {object} category - category
     * @returns {string} - the category name
     */
    getCategoryName(category) {
      let name = category.name || "";

      if (name.length > 25) {
        name = name.substring(0, 25) + "...";
      }

      return name;
    },
    /**
     * It toggles the children
     *
     * @returns {void}
     **/
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    /**
     * It emits the click event
     *
     * @param {object} category - category
     * @returns {void}
     */
    onCategoryClick(category) {
      this.$emit("click", category);
    },
  },
};
