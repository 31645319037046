/**
 * ```
 * @prettier
 * ```
 */

// Mixins
import CartMixin from "@/mixins/cartMixin.js";

export default {
  name: "CartHeaderComponent",
  mixins: [CartMixin],
  methods: {
    /**
     * It returns the cart title
     *
     * @returns {string} Cart title
     **/
    getCartTitle() {
      return this.inBulkMode() ? " Swag Items" : "Pack Content";
    },
  },
};
