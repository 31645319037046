<template>
  <div id="app">
    <head>
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
        rel="stylesheet"
      >
    </head>
    <router-view />
  </div>
</template>

<script>
/* LogRocket */
import LogRocket from "logrocket";
if (process.env.VUE_APP_LOGROCKET_APP_ID) {
    LogRocket.init(process.env.VUE_APP_LOGROCKET_APP_ID);
}

/**
 * Services
 */
import UserService from "@/api/services/resources/user";

/**
 * Mixins
 */
import CartMixin from "@/mixins/cartMixin.js";
import UserMixin from "@/mixins/userMixin.js";
import ProductMixin from "@/mixins/productMixin.js";


export default {
  name: "SwagPackBuilder",
  components: {
  },

  mixins: [CartMixin, UserMixin, ProductMixin],

  async created() {
    this.initialiseClarity();
    this.setAppMode();
    await this.checkIfGuest();
    await this.initialiseCart(); // cart Mixin
  },

  /**
   * Adds a window resize listener
   *
   * @returns {void}
   */
   mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();

    // Opens product modal if "product" query param is present
    this.showProductViaId(Number(this.$route.query.product));
  },

  methods: {
    /**
     * Adds Microsoft Clarity
     * 
     * @returns {void}
     */
    initialiseClarity() {
      if (process.env.VUE_APP_DEV === 'true') {
        return;
      }
      (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "gck4xvojif");
    },

    /**
     * Checks if user is a guest
     * 
     * @returns {void}
     */
    async checkIfGuest() { 
      // remove the overide token   
      if (this.$store.getters["user/getToken"] === null) {
        localStorage.removeItem("token-override");
      }

      let isGuest = false;

      const user = await UserService.me();
      if (user.id === Number(process.env.VUE_APP_API_GUEST_ID)) {
        isGuest = true;
      }

      this.$store.dispatch("user/changeIsGuest", isGuest);
      this.$store.dispatch("user/changePortalId", this.$route.query.portalId);

      if (!isGuest) {
        this.getUserDetails(); // user mixin
      }
    },

    /**
     * Sets the application mode
     * e.g. bulk
     * 
     * @returns {void}
     */
    setAppMode() {
      this.$store.dispatch(
        "global/changeIsCatalogueModeState",
        this.$route.query.catalogueMode ? 1 : 0
      );

      this.$store.dispatch(
        "global/changeIsBulkState",
        this.$route.query.isBulk ? 1 : 0
      );
    },

    /**
     * Stores the current window size in vuex
     * 
     * @returns {void}
     */
    onWindowResize() {
      this.$store.dispatch("global/changeWindowWidth",window.innerWidth);
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

html {
    body {
        font-family: "Montserrat";
    }
}

/* 
  Bootstrap tooltip styling 
*/
.tooltip .tooltip-inner{
    background-color: $tooltip-bg;
}
</style>
