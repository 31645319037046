/**
 * ```
 * @prettier
 * ```
 */
import axios from "axios";
import Vue from "vue";

import store from "../store/index.js";
/**
 * It attaches to the request all required header's information
 */
axios.interceptors.request.use(
  (config) => {
    let url = window.location.href;
    let urlParams = new URLSearchParams(url);
    let token = urlParams.get("token");

    // if guest and hitting a cart endpoint, don't use the guest token
    if (store.getters["user/getIsGuest"] && config.url.includes("/cart")) {
      config.withCredentials = true;
      return config;
    }

    /**
     * Check if URL contains token to authorize API requests
     */
    if (token) {
      localStorage.setItem("token", token);
    }
    /**
     * If not gets from localStorage
     */
    if (!token) {
      token = localStorage.getItem("token");
    }

    /**
     * Use override token if exists
     */
    if (localStorage.getItem("token-override")) {
      token = localStorage.getItem("token-override");
    }

    config.headers.common["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * Throws an error when response is not successfull
 */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const vm = new Vue();
    vm.$notification.error({
      message: "Error",
      description: error.response.data.message,
    });
    return Promise.reject(error);
  }
);
export default axios;
